import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { AuthState, AuthStatus } from './types';
import { RootState } from '../types';

export const state: AuthState = {
    access_token: '',
    refresh_token: '',
    mfa_current: null,
    status: AuthStatus.SignedOut,
    mfa_methods: [],
    user: {
        username: '',
        first_name: '',
        last_name: '',
        permission_groups: [],
        constraints: {

        }
    },
};

const namespaced: boolean = true;

export const auth: Module<AuthState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};

import { Layers, Sources } from '@/store/map/types';

const railShadow = 'rgba(255, 255, 255, 0.8)';


var roadsLayout = {
    'line-cap': 'round',
    'line-join': 'round',
};


export var railShadowsStyle = (source: Sources) => {

    return {
        id: Layers.RailBackground,
        type: 'line',
        source: source,
        'source-layer': 'rail',
        layout: roadsLayout,
        paint: {
            'line-width': {
                stops: [
                    [8, 2.5],
                    [16, 14]
                ],
            },
            'line-color': railShadow,
        }
    }
}


export var railStyle = (source: Sources) => {

    return {
        id: Layers.Rail,
        type: 'line',
        source: source,
        'source-layer': 'rail',
        layout: roadsLayout,
        paint: {
            'line-width': {
                stops: [
                    [7, 3],
                    [14, 14],
                    [18, 20]

                ],
            },
        }
    }
}


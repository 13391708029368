




























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import parsePhoneNumber from 'libphonenumber-js';
import { isNullOrUndefined } from 'util';

import { mixins } from 'vue-class-component';
import Validation from './validation-mixin'

import { VueRecaptcha } from 'vue-recaptcha';

@Component({
    components: {
        VueRecaptcha
    }
})
export default class Register extends mixins(Validation) {

    @Action('auth/register') register: any;
    @Action('auth/verifyRecaptcha') verifyRecaptcha: any;

    user: any = { phone_number: '' }; // Note need to initialise mobile number with something other than undefined so the the phone_number validator parses.

    error_message: string = ''
    get error() {
        return this.error_message !== ''
    }

    // reCAPTCHA settings
    siteKey = "6LfYcFgpAAAAALOhebM4_a6mLJPu5vAalw5CkeIl"
    widget_id = null;

    loading: boolean = false;
    registration_complete = false;
    showPassword = false;

    onSubmit() {
        this.error_message = '';
        this.loading = true;
        (this.$refs.recaptcha as any).reset(this.widget_id);
        (this.$refs.recaptcha as any).execute(this.widget_id);
    }

    onRecaptchaVerify(token: any) {
        console.log("onRecaptchaVerify");
        console.log(token);

        if (token.length > 0) {

            var vm = this;
            this.verifyRecaptcha(token)
                .then(function (response: any) {
                    console.log("reCAPTCHA verification successful!")
                    vm.onRegister();
                })
                .catch(function (error: any) {
                    console.log("reCAPTCHA verification failed!")
                    console.log(error)
                    vm.loading = false;
                    var msg = 'An error has occurred. Try again shortly.';
                    if (error.response.data.recaptcha) {
                        msg = error.response.data.recaptcha[0];
                    }
                    vm.error_message = msg;
                }).finally(function () {
                    (vm.$refs.recaptcha as any).reset(vm.widget_id);
                });
        }
    }

    onRecaptchaRendered(widget_id: any) {
        this.widget_id = widget_id;
    }

    onRecaptchaExpired() {
        this.error_message = 'reCAPTCHA has expired.';
    }

    onRecaptchaError() {
        this.error_message = 'A reCAPTCHA error occurred.';
    }



    onRegister() {

        var vm = this;
        vm.error_message = '';
        vm.loading = true;

        this.user.password_confirm = this.user.password;    // Set confirmation password to provided password.
        this.user.username = this.user.email;               // Set username to be the email address.

        // Update the phone number with the international parsed number.
        var pn = parsePhoneNumber(this.user.phone_number, 'AU')
        if (!isNullOrUndefined(pn)){
            this.user.phone_number = pn.number;
        }

        this.register(this.user)
            .then(function (response: any) {
                //console.log(response)
                vm.registration_complete = true;
            })
            .catch(function (error: any) {
                console.log(error)
                var msg = 'An error has occurred. Try again shortly.';
                if (error.response.status === 429) {
                    msg = error.response.data.detail.replace('Request was throttled. Expected available in', 'Too many attempts. Try again in');
                }
                if (error.response.headers['content-type'] == 'application/json') { // Sends html error on say e-mails.
                    for (const field of Object.keys(error.response.data)) {
                        msg = error.response.data[field][0];
                        break;
                    }
                }
                vm.error_message = msg;
            }).finally(function () { vm.loading = false; });
    }
}


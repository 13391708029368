import { GetterTree } from 'vuex';
import { AuthState, AuthStatus } from './types';
import { RootState } from '../types';
import Vue from 'vue';
import { isNullOrUndefined } from 'util';
import { jwtDecode } from "jwt-decode";

export const getters: GetterTree<AuthState, RootState> = {

    isMfaRequired(state): boolean {
        return true;
        // These users may: deactivate their MFA; sign out; and the sign back in with just a username and password.
        var not_required = ['admin', 'devteam', 'pen_test'];
        return !not_required.includes(state.user.username.toLowerCase());
    },

    isAuthenticated(state): boolean {
        return !!state.access_token;
    },

    accessToken(state): any {
        if (state.access_token) {
            return jwtDecode(state.access_token);
        }
    },

    refreshToken(state, getters): any {
        if (state.refresh_token) {
            return jwtDecode(state.refresh_token);
        }
    },

    accessTokenLifeMs(state, getters): any {
        return (date: Date) => {
            if (getters.accessToken) {
                return (getters.accessToken.exp * 1000 - date.getTime()); // Token exp is reported in seconds.
            } else {return 0}
        }
    },

    refreshTokenLifeMs(state, getters) {
        return (date: Date) => {
            if (getters.refreshToken) {
                return (getters.refreshToken.exp * 1000 - date.getTime()); // Token exp is reported in seconds.
            } else { return 0 }
        }
    },

    isAccessTokenExpired(state, getters): any {
        return (date: Date) => {
            if (getters.accessToken) {
                return getters.accessTokenLifeMs(date) <= 0;
            } else { return true }
        }
    },

    isRefreshTokenExpired(state, getters): any {
        return (date: Date) => {
            if (getters.refreshToken) {
                return getters.refreshTokenLifeMs(date) <= 0;
            } else { return true }
        }
    },

    isMfaActivated(state, getters): boolean {
        return (state.mfa_methods.length > 0 && getters.isAuthenticated === true) || !isNullOrUndefined(state.mfa_current);
    },

    isAuthenticatedAndMfaActivated(state, getters): boolean {
        return getters.isAuthenticated && getters.isMfaActivated;
    },

    constrainedValues(state): any {
        return (filter: string) => { return (state.user.constraints as any)[filter]; }
    },

    isConstrained(state): any {
        return (filter: string) => {
            if (!(state.user.constraints as any)[filter]) { return false; }
            else {
                return (state.user.constraints as any)[filter].length > 0;
            };
        }
    },

    authStatus(state): AuthStatus {
        return state.status;
    },

    username(state): string {
        return state.user.username;
    },

    displayName(state): string {
        if (state.user.first_name !== '') {
            if (state.user.last_name !== '') {
                return state.user.first_name + ' ' + state.user.last_name;
            } else {
                return state.user.first_name;
            }
        } else {
            return state.user.username;
        }
    },

    firstName(state): string {
        return state.user.first_name;
    },

    hasPermission(state): any { // Actually returns an anonymous function which can then accept an argument (perm).
        return (perm: string) => {
            //if (!isNullOrUndefined(state.user.username)) {
                return state.user.permission_groups.includes(perm);
            //} else {
            //    return false;
            //}
        };
    },

    isAdmin(state): boolean {
        return state.user.username === 'admin';
    },

    isAllowedSensitiveInfo(state, getters): boolean {
        return getters.hasPermission('transit_web.view_sensitive_info');
    },

    isAcsUser(state, getters): boolean {
        return getters.hasPermission('transit_web.view_acs_user');
    },

    isScenarioUser(state, getters): boolean {
        return getters.hasPermission('transit_web.view_scenario_user');
    },

};



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    components: {
    }
})
export default class CO2 extends Vue {

    @Prop() private data!: any;

    get co2(): string {
        return this.numberWithCommas(Math.round(this.data.co2_tn))
    }

    numberWithCommas(x: any): string {
        return Number(x).toLocaleString()
    }
}


























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { MapState, Layers } from '@/store/map/types';

import InfoPanelRoad from './InfoPanelRoad.vue';
import InfoPanelRail from './InfoPanelRail.vue';
import InfoPanelEnterprise from './InfoPanelEnterprise.vue';
import InfoPanelBlockedEnterprise from './InfoPanelBlockedEnterprise.vue';

import { DatasetState, DensityType } from '@/store/datasets/types';

import { endpoints } from "@/endpoints";

import { isNullOrUndefined } from 'util';
import buildUrl from 'build-url';



@Component({
    components: {
        InfoPanelRoad,
        InfoPanelRail,
        InfoPanelEnterprise,
        InfoPanelBlockedEnterprise,
    }
})
export default class InfoPanel extends Vue {

    @Prop() private densityType!: DensityType;
    @Prop() private statisticType!: string;
    @Prop() private queryParamsCallback!: any;

    @State('datasets') datasets!: DatasetState;

    @State('map') mapstate!: MapState;
    @Getter('map/isInspecting') isInspecting!: boolean;

    link_stats = null; // Property for holding updated stats (i.e. when filters are applied)

    get dataset(): any {
        return this.datasets.dataset;
    }

    // Is the feature of interest a background layer.
    get isBackgroundLayer(): boolean {
        return [Layers.RoadsBackground, Layers.RailBackground].includes(this.mapstate.featureOfInterest.layer.id);
    }

    // Is the feature of interest a netowrk link.
    get isLink(): boolean {
        return !isNullOrUndefined(this.mapstate.featureOfInterest) && !isNullOrUndefined(this.mapstate.featureOfInterest.properties.link_id);
    }

    get isRoadInfo(): boolean {
        return [Layers.Roads, Layers.RoadsFiltered, Layers.RoadsBackground, Layers.RoadEventsBaseline, Layers.RoadEventsScenario].includes(this.mapstate.featureOfInterest.layer.id);
    }

    get isRailInfo(): boolean {
        return [Layers.Rail, Layers.RailBackground].includes(this.mapstate.featureOfInterest.layer.id);
    }

    get isEnterpriseInfo(): boolean {
        return [Layers.Enterprises, Layers.EventsEnterprisesBaseline].includes(this.mapstate.featureOfInterest.layer.id);
    }

    get isBlockedEnterpriseInfo(): boolean {
        return [Layers.BlockedEnterprisesUnaffected, Layers.BlockedEnterprisesPartial, Layers.BlockedEnterprisesTotally].includes(this.mapstate.featureOfInterest.layer.id);
    }

    get unitType(): string {
        //return this.isRoadInfo ? 'Trailers' : 'Wagons'
        if (this.densityType === DensityType.Rail) {
            return 'Wagons'
        } else {
            return 'Trailers'
        }
    }

    // If there are no statistics to display in the info panel.
    updatedInfo: boolean = true;
    get hidecounts(): boolean {
        return this.isBackgroundLayer || !this.updatedInfo; // It is a background layer, or stats no stats data has beed fetched.
    }

    link_chart_data: any = {
        link_movements: { endpoint: endpoints.linkStatsByCommodityUrl, value: null },
        link_movements_industry: { endpoint: endpoints.linkStatsByIndustryUrl, value: null },
        link_movements_desttype: { endpoint: endpoints.linkStatsByDestTypeUrl, value: null },
        link_movements_origtype: { endpoint: endpoints.linkStatsByOrigTypeUrl, value: null },
        link_movements_temporal: { endpoint: endpoints.linkStatsByMonthUrl, value: null, worksWith: [DensityType.Road, DensityType.Rail] }, // Curently it's not used in Events as as we do not have the monthly subset of movements that is used for acs reporting.
        link_movements_direction: { endpoint: endpoints.linkDirectionUrl, value: null, worksWith: [DensityType.Road, DensityType.Road_Events_Baseline, DensityType.Road_Events_Scenario] },
        link_movements_cost: { endpoint: endpoints.linkCostUrl, value: null },
    }

    // Extract just the data values for the charts.
    get chart_data(): any {

        var ret: any = {}

        Object.keys(this.link_chart_data).forEach((key: string) => {
            if (!isNullOrUndefined(this.link_chart_data[key].value)) {
                ret[key] = this.link_chart_data[key].value
            }
        });

        return ret;
    }



    @Watch('isInspecting', { deep: true })
    onIsInspectingChanged(val: any, oldVal: any) {

        // The feature is already in the store containing the clicked location attributes,
        // so only update the charts, and get the attributes off the store feature.
        if (val) { this.fetchLinkStats(true); }

    }



    // Fetch all the data displayed in the info panel.
    fetchLinkStats(chartsOnly: boolean = false) {

        if (!this.isLink) { return } // Only run if a link is being inspected.

        var vm: any = this;
        var queryParams = vm.queryParamsCallback();
        var link_id = this.mapstate.featureOfInterest.properties.link_id

        // Get the stats displayed on the score cards.
        if (!chartsOnly) {

            Vue.axios({
                url: buildUrl(endpoints.linkStatsUrl(this.dataset, this.densityType, link_id), { queryParams: queryParams })
            }).then((response: any) => {
                //console.log(response);
                if (!isNullOrUndefined(response.data[0])) {
                    vm.link_stats = response.data[0];
                    vm.updatedInfo = true;
                }
                else {
                    vm.link_stats = null;
                    vm.updatedInfo = false;
                }
            }, (error: any) => {
                console.log(error);
            });

        }

        // Loop over each info panel dropdown, fetching the required data.
        Object.keys(this.link_chart_data).forEach((key: string, index: number) => {

            if (isNullOrUndefined(vm.link_chart_data[key].worksWith) || vm.link_chart_data[key].worksWith.includes(vm.densityType)) {

                Vue.axios({
                    url: buildUrl(vm.link_chart_data[key].endpoint(this.dataset, this.densityType, link_id), { queryParams: queryParams })
                }).then((response: any) => {
                    //console.log(response);
                    vm.link_chart_data[key].value = response.data;
                }, (error: any) => {
                    console.log(error);
                });
            }
            else {
                vm.link_chart_data[key].value = null;
            }

        });

    }

    onCloseInfoPanel(e: any) {

        this.link_stats = null;  // Clear the stats

        // Clear the chart data
        Object.keys(this.link_chart_data).forEach((key: string) => {
            this.link_chart_data[key].value = null;
        });

        this.$emit('closed', e)
    }


}




<script>

    import { MglVectorLayer } from 'vue-mapbox';

    // Fixes bugs in MglVectorLayer related to the sourceLayer key.
    MglVectorLayer.methods.setFeatureState = function (featureId, state) {
        if (this.map) {
            const params = {
                id: featureId,
                source: this.sourceId,
                sourceLayer: this.layer["source-layer"]
            };
            return this.map.setFeatureState(params, state);
        }
    }

    // Fixes bugs in MglVectorLayer related to the sourceLayer key.
    MglVectorLayer.methods.getFeatureState = function (featureId) {
        if (this.map) {
            const params = {
                id: featureId,
                source: this.source,
                sourceLayer: this.layer["source-layer"]
            };
            return this.map.getFeatureState(params);
        }
    }

    // Fixes bugs in MglVectorLayer related to the map.loaded() inconsistencies. Note: this does not override the base function, it will merge with the base function(hence two hook functions).
    MglVectorLayer.beforeDestroy = function () {
        if (this.map) { // Removed map.loaded() condition.
            let existed = this.map.getLayer(this.layerId); // Added this check because the base beforeDestroy() gets called first and can't be overrided.
            if (existed) {
                try {
                    this.map.removeLayer(this.layerId);
                } catch (err) {
                    this.$_emitEvent("layer-does-not-exist", {
                        layerId: this.sourceId,
                        error: err
                    });
                }
            }
            if (this.clearSource) {
                try {
                    this.map.removeSource(this.sourceId);
                } catch (err) {
                    this.$_emitEvent("source-does-not-exist", {
                        sourceId: this.sourceId,
                        error: err
                    });
                }
            }
        }
    }

    export default MglVectorLayer;
    
</script>
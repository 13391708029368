export interface AuthState {
    access_token: string; // The token to use once completely authenticated.
    refresh_token: string; // The token used to refresh access tokens.
    status: AuthStatus;
    user: {
        username: string,
        first_name: string,
        last_name: string,
        permission_groups: string[],
        constraints: {
            boundary1?: [string],
            boundary2?: [string],
            boundary3?: [string],
            boundary4?: [string],
            boundary5?: [string],
            boundary6?: [string],
            commodity?: [number],
            destenterprise?: [string],
            commorigenterpriseodity?: [string],
            accesstype?: [string],
            surfacetype?: [string],
        }
    };
    // Note: MFA methods in an array but will only ever hold 1 item, since we constrain TraNSIT Web Users to their 'preferred' MFA method.
    mfa_methods: any[]; // Contains response from: https://django-trench.readthedocs.io/en/latest/endpoints.html#get-user-s-active-mfa-methods

    // Note: this is the response from the first step (contains the MFA method to use and the ephemeral_token for the second step)
    mfa_current: any;   // Contains response from: https://django-trench.readthedocs.io/en/latest/endpoints.html#login-first-step-jwt-example
}

export enum AuthStatus {
    Success = "success",
    Loading = "loading",
    MFA = "mfa",
    SignedOut = "signed_out",
    Error = "error",

}























































import { Component, Prop, Vue, Watch, Inject } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { Layers } from '@/store/map/types';

import LegendItem from '@/components/legends/LegendItem.vue';

@Component({
    components: {
        LegendItem,
    }
})
export default class InteractiveLegend extends Vue {


    @Action('map/setLayerVisibility') setLayerVisibility: any;
    @Getter('map/isLayerVisible') isLayerVisible!: any; // Returns an anonymous function

    // Is the interactive legend expanded/hiden?
    show_interactive_legend: boolean = true;

    // Refactor Notes: These layer id's may need to be implemented better.
    get layers(): any {

        var vm = this;

        return {
            [Layers.ClosedRoads1]: {
                items: [{ color: "#F7455D", value: "Closed roads", icon_style: "legend-line", icon: "mdi mdi-minus" } ],
                get selected() {
                    return vm.isLayerVisible(Layers.ClosedRoads1)
                },
                set selected(val: string) {
                    vm.setLayerVisibility({ layer: Layers.ClosedRoads1, value: val });
                }
            },
            [Layers.ClosedRoads7]: {
                items: [{ color: "#964B00", value: "Closed roads > 7 days", icon_style: "legend-line", icon: "mdi mdi-minus" }],
                get selected() {
                    return vm.isLayerVisible(Layers.ClosedRoads7)
                },
                set selected(val: string) {
                    vm.setLayerVisibility({ layer: Layers.ClosedRoads7, value: val });
                }
            },
            [Layers.BlockedEnterprisesOrigin]: {
                items: [{ color: "purple", outlined: "outlined", value: "Affected outbound enterprises", icon_style: "legend-circle", icon: "mdi mdi-circle-outline" }],
                get selected() {
                    return vm.isLayerVisible(Layers.BlockedEnterprisesOrigin)
                },
                set selected(val: string) {
                    vm.setLayerVisibility({ layer: Layers.BlockedEnterprisesOrigin, value: val }); }
            },
            [Layers.BlockedEnterprisesDestination]: {
                items: [{ color: "orange", outlined: "outlined", value: "Affected inbound enterprises", icon_style: "legend-circle", icon: "mdi mdi-circle-outline" }],
                get selected() {
                    return vm.isLayerVisible(Layers.BlockedEnterprisesDestination)
                },
                set selected(val: string) { vm.setLayerVisibility({ layer: Layers.BlockedEnterprisesDestination, value: val }); }
            },

        }
    }
}



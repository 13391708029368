import { ActionTree } from 'vuex';
import { TablesState, ITable } from './types';
import { RootState } from '../types';

import Vue from 'vue';


export const actions: ActionTree<TablesState, RootState> = {

    setStandAlone({ commit }, isStandAlone: boolean) {
        commit('setIsStandAlone', isStandAlone);
    },

    setSyncWithMap({ commit }, syncWithMap: boolean) {
        commit('setSyncWithMap', syncWithMap);
    },

    setDownloadDisabled({ commit }, isDownloadDisabled: boolean) {
        commit('setIsDownloadDisabled', isDownloadDisabled);
    },
    setTableDownloading({ commit, state }, isTableDownloading: boolean) {
        for (var table in state.reports) {
            commit('setTableDownloading', { table: table, value: isTableDownloading });
        }
    },

    setQueryParams({ commit }, queryParams: any) {
        commit('setQueryParams', queryParams);
    },

    loadTable({ commit, state }, payload: ITable) {

        return new Promise((resolve, reject) => {

            commit('setTableLoading', { table: payload.table, value: true });
            commit('setTableData', { table: payload.table, value: [] });

            Vue.axios({
                url: payload.url
            }).then((response: any) => {
                if (state.reports[payload.table].isLoading) { // Only update the data if loading was not cancelled.
                    commit('setTableData', { table: payload.table, value: response.data });
                    commit('setTableLoading', { table: payload.table, value: false });
                }
                resolve(response.data);
            }, (error: any) => {
                commit('setTableLoading', { table: payload.table, value: false });
                console.log(error);
                reject(error);
            });

        });
    },

    downloadTable({ commit, state }, payload: ITable) {

        return new Promise((resolve, reject) => {

            commit('setTableDownloading', { table: payload.table, value: true });

            Vue.axios({
                url: payload.url
            }).then((response: any) => {
                commit('setTableDownloading', { table: payload.table, value: false });
                resolve(response);
            }, (error: any) => {
                commit('setTableDownloading', { table: payload.table, value: false });
                console.log(error);
                reject(error);
            });

        });
    },

    clearTablesLoading({ commit, state }) {
        for (var table in state.reports) {
            // check if the property/key is defined in the object itself, not in parent
            if (state.reports.hasOwnProperty(table)) {
                commit('setTableLoading', { table: table, value: false });
            }
        }
    },

    clearTableData({ commit, state }) {
        for (var table in state.reports) {
            // check if the property/key is defined in the object itself, not in parent
            if (state.reports.hasOwnProperty(table)) {
                commit('setTableData', { table: table, value: [] });
            }
        }
    }

};































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

@Component({
    components: {
    }
})
export default class MFA_Deactivation extends Vue {

    @Action('auth/MFA_deactivate') deactivate: any;
    @Getter('auth/isAdmin') isAdmin?: boolean;

    loading: boolean = false;

    onDeactivate(context: any) {
        this.loading = true;
        this.deactivate();
    }

}


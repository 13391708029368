





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

@Component({
    components: {
    }
})
export default class MFA_Activation extends Vue {

    @Action('auth/MFA_config') mfa_config: any;
    @Getter('auth/isAdmin') isAdmin?: boolean;

    methods = []
    selected_method: string = ''
    loading: boolean = false;

    // When component is created, fetch the available MFA methods which can be chosen.
    created() {

        var vm = this;
        this.mfa_config({})
            .then(function (response: any) {
                vm.methods = response.data.methods
            })
            .catch(function (error: any) {
                console.log(error)
            });

    }

    method_names = {
        'app': 'Mobile Authenticator',
        'sms_twilio': 'SMS',
        'call_twilio': 'Phone Call',
        'email': 'E-Mail',
        'yubi': 'YubiKey',
        'yubiadmin': 'YubiKey for Administrators'
    }
    get available_methods() {

        // Convert methods retrieved from API into JSON definitions. (easier to code with)
        var methods_object = this.methods.map((method) => ({ id: method, name: this.method_names[method] }))

        // If admin, must choose 'yubiadmin'. If not admin, can choose from the remainder.
        return this.isAdmin ? methods_object.filter(method => method.id == 'yubiadmin') : methods_object.filter(method => method.id != 'yubiadmin');

    }


    onActivate(context: any) {

        this.loading = true;

        // Get the chosen method from the list of methods
        var method = this.available_methods.find(method => method.id == this.selected_method)

        // Send event
        this.$emit('activate_mfa', method)
    }

}


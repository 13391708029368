import { ActionTree } from 'vuex';
import { MapState, lngLat, ILayerVisibility } from './types';
import { RootState } from '../types';


export const actions: ActionTree<MapState, RootState> = {

    setLayerStyle({ commit }, layerStyle: string): any {
        commit('setLayerStyle', layerStyle);
    },
    setMarker({ commit }, marker: lngLat): any {
        commit('setMarker', marker);
    },
    setFlyTo({ commit }, location: number[]): any {
        commit('setFlyTo', location);
    },
    setFeatureOfInterest({ commit }, feature: any): any {
        commit('setFeatureOfInterest', feature);
    },
    setLayerVisibility({ commit }, payload: ILayerVisibility): any {
        commit('setLayerVisibility', payload);
    },


};
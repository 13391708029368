



























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import WelcomeMessage from '@/components/assistant/WelcomeMessage.vue';
import Message from '@/components/assistant/Message.vue';
import RelatedQuestions from '@/components/assistant/RelatedQuestions.vue';
import { AssistantState } from '@/store/assistant/types';

import markdownit from 'markdown-it';

import { isNullOrUndefined } from 'util';

@Component({
    components: {
        WelcomeMessage,
        Message,
        RelatedQuestions
    }
})
export default class Conversation extends Vue {

    @Prop() private subjects!: any[];
    @Prop() private messages!: any[];
    @Prop() private progressiveMessage!: any;


    @State('assistant') assistant!: AssistantState;

    @Getter('assistant/assistantName') assistantName!: string;
    @Getter('auth/firstName') firstName!: string;

    messages_length = 0

    getMarkdown(content: string) {
        if (content) {
            var result = markdownit().renderInline(content.trim());
        }
        return result;
    }

    @Watch('messages', { deep: false })
    onMessagesChanged(val: any, oldVal: any) {

        Vue.nextTick(() => {
            if (this.assistant.playAudioResponses) {
                if (this.messages_length == val.length - 1) {
                    if (val[val.length - 1].role == 'assistant') {
                        let msg = (this.$refs['message-' + (val.length - 1)] as any)[0];
                        msg.playAudio();
                    }
                }
            }
            this.messages_length = val.length;
        })
    }

    subjectLabel(subject: string) {
        if (!isNullOrUndefined(subject)) {
            return this.subjects.find((item: any) => item.id === subject).label
        }
    }

}


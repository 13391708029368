import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { EventsState } from './types';
import { RootState } from '../types';

export const state: EventsState = {
    enterprise_legend: false,
    report_on: 'events',
    report_type: 'Event',
    delta_trailers: 'all',
    event_date: '',
    blocked_enterprise_id: undefined,
    event_date_items: [],
};

const namespaced: boolean = true;

export const events: Module<EventsState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};




































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import VueQrcode from 'vue-qrcode'

@Component({
    components: {
        VueQrcode,
    }
})
export default class MFA_QRCode extends Vue {

    @Prop() private value!: string;

    dialog_learn_more = false;
    dialog_scan = false;

    get url() {
        var url = this.value != "" ? new URL(decodeURIComponent(this.value)) : null;
        console.log(url)
        return url;
    }

    get issuer() {
        return this.url ? this.url.searchParams.get("issuer") : ""
    }

    get secret() {
        return this.url ? this.url.searchParams.get("secret") : ""
    }

    get account() {
        var account = ""
        if (this.url) {
            account = this.url.pathname.replace("//totp/", "").replace((this.issuer as any), "").replace(":", "");
        }
        return account;
    }

    //get qr_code_url() {
    //    //return encodeURI('https://www.google.com/chart?chs=200x200&chld=M|0&cht=qr&chl=' + this.value);
    //    return encodeURI('https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=' + this.value);
    //}


}


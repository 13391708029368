











































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { DensityType, DatasetState } from '@/store/datasets/types';

@Component({
    components: {
    }
})
export default class CommoditiesReport extends Vue {

    @Prop() private reportConfig!: any;
    @Prop() private reportData!: any;
    @Prop() private isDownloadDisabled!: boolean;
    @Prop() private downloading_report!: boolean;
    @Prop() private isStandAlone!: boolean;
    @Prop() private statisticType!: string;

    @State('datasets') datasets!: DatasetState;

    DensityType: any = DensityType;

    get densityType(): DensityType {
        return this.datasets.type;
    }

    numberWithCommas(x: any): string {
        return Number(x).toLocaleString()
    }

    total_group(groupBy: string, group: string, attribute: string) {
        let sum = 0;
        return this.reportData.reduce((sum: number, item: any) => sum + (item[groupBy] == group ? item[attribute] : 0), 0);
    }

}


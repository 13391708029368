
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';


@Component({
    components: {
    }
})
export default class WelcomeMessage extends Vue {

    @Getter('assistant/assistantName') assistantName!: string;
    @Getter('auth/firstName') firstName!: string;
}




















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { State, Action, Getter } from 'vuex-class';
import { AuthState, AuthStatus } from '@/store/auth/types';
import { isNullOrUndefined } from 'util';
import { DatasetState } from '@/store/datasets/types';


import MFA_Activation from '@/components/auth/MFA_Activation.vue';
import MFA_Deactivation from '@/components/auth/MFA_Deactivation.vue';
import MFA_QRCode from '@/components/auth/MFA_QRCode.vue';

@Component({
    components: {
        MFA_Activation,
        MFA_Deactivation,
        MFA_QRCode
    }
})
export default class MFA extends Vue {

    @State('auth') auth!: AuthState;
    @State('datasets') datasets!: DatasetState;

    //@Action('auth/login') login: any;    // Django login process
    @Action('auth/loginMFA') login: any;   // Django Trench login process
    @Action('auth/MFA_activate') activate: any;
    @Action('auth/MFA_activate_confirm') confirm: any;
    @Action('auth/MFA_request_code') request_code: any;


    @Getter('auth/isAuthenticated') isAuthenticated?: boolean;
    @Getter('auth/isMfaActivated') isMfaActivated?: boolean;

    @Prop() private credentials!: any;

    isActivating: boolean = false
    isLoggingIn: boolean = false;

    valid: boolean = true;
    selected_method: any = {};
    status_response: string = '';
    qr_link: string = '';

    login_error: string = ''


    // Response messages from Django-Trench are provided on activation, but not login (because the ephemeral_token and MFA method are provided in the response), so need to provide some.
    init_messages: any = {
        sms_twilio: "SMS message with MFA code has been sent.",             // Similar message to activation, but activation contains mobile number in response.
        email: "E-Mail message with MFA code has been sent.",               // Similar message to activation, but activation contains e-mail address in response.
        call_twilio: "Answer your phone to receive your MFA code...",       // Same message as activation.
        app: "Enter your MFA code provided by your mobile authenticator:",  // No message at all, because qr_link is only provided in the activation response.
        yubi: "Generate code using YubiKey:",                               // Same message as activation.
        yubiadmin: "Generate code using YubiKey:"                           // Same message as activation.
    }

    rules: any = {
        code: [
            function (v: any) {
                return (Number.isInteger(Number(v)) || v === '') || "Numeric characters only"
            },
        ],
        yubi_code: []
    }

    otp_code: string = '';
    @Watch('otp_code', { deep: true })
    onOTPChanged(val: any, oldVal: any) {
        this.onLogin()
    }

    // Is the user selecting an MFA method on the activation window?
    get isSelectingMethod(): boolean {
        return !this.isMfaActivated && !this.isActivating && !this.isLoggingIn
    }

    // Is the user already authenticated and have MFA activation (i.e. they probably stumbled across /MFA by mistake)
    get canDeactivate(): boolean | undefined {
        return this.isMfaActivated && this.isAuthenticated && !this.isLoggingIn
    }

    // Is the chosen method Mobile Authenticator?
    get isAppInit(): boolean {
        return (!isNullOrUndefined(this.selected_method.id) && this.selected_method.id.startsWith('app'))
    }

    isMethod(method: string): boolean {
        return (!isNullOrUndefined(this.selected_method.id) && this.selected_method.id.startsWith(method)) ||
            (!isNullOrUndefined(this.auth.mfa_current) && this.auth.mfa_current.method.startsWith(method))
    }

    // Is the chosen method Mobile Authenticator?
    get isAppMethod(): boolean {
        return this.isMethod("app");
    }

    // Is the chosen method YubiKey?
    get isYubiKeyMethod(): boolean {
        return this.isMethod("yubi");
    }

    // Is the chosen method Email?
    get isEmailMethod(): boolean {
        return this.isMethod("email");
    }

    // Is the chosen method SMS?
    get isSmsMethod(): boolean {
        return this.isMethod("sms_twilio");
    }

    // Is the chosen method Phone call?
    get isCallMethod(): boolean {
        return this.isMethod("call_twilio");
    }

    // OTP is 44 characters for YubiKey and 6 digits for all other methods.
    get otp_length(): number {
        return this.isYubiKeyMethod ? 44 : 6
    }

    // OTP Code must be full length to display error.
    get otp_error(): boolean {
        return this.auth.status === AuthStatus.Error && this.otp_code.length === this.otp_length;
    }

    get loading(): boolean {
        return this.auth.status === AuthStatus.Loading;
    }

    created() {

        if (this.isMfaActivated) {
            if (this.isAuthenticated) {
                this.status_response = this.init_messages[this.auth.mfa_methods[0].name];   // Already all good.
            } else {
                this.status_response = this.init_messages[this.auth.mfa_current.method];    // Already activated, just logging in.
            }
        }
    }


    onRequestCode() {
        if (this.isActivating) {
            this.onActivateMethod(this.selected_method)     // Re-run the MFA activation request to get new MFA request.
        } else {
            if (isNullOrUndefined(this.credentials)) {
                this.$router.push("/Signin")
            } else {
                var vm = this;
                this.status_response = 'Preparing MFA code...'
                this.login(this.credentials)  // Re-run the login request to get new MFA request.
                    .then(function (response: any) {
                        vm.status_response = vm.init_messages[vm.auth.mfa_current.method];                         // Set the returned message for other methods.
                    })
                    .catch(function (error: any) {
                        console.log(error)
                        vm.status_response = error
                    });
            }
        }
    }



    // Activtes the method which was selected from the options.
    onActivateMethod(context: any) {

        this.isActivating = true;
        this.selected_method = context;

        // For the methods that need to send a code to the user - set a status message while this is happening!.
        if (['email', 'sms_twilio', 'call_twilio'].includes(this.selected_method.id)) {
            this.status_response = 'Preparing MFA code...'
        } else if (['yubi', 'yubiadmin'].includes(this.selected_method.id)) {
            this.status_response = 'Generate code using YubiKey:'
        } else if (this.selected_method.id == 'app') {
            this.status_response = this.init_messages[this.selected_method.id] // No message returned when 'app' method. So need to set one here. (small delay if placed in activate.then())
        }

        var vm = this;
        this.activate({ 'method': this.selected_method.id })
            .then(function (response: any) {
                if (vm.selected_method.id === 'app') {
                    vm.qr_link = response.data.details;                                 // Set the QR code link
                } else {
                    vm.status_response = response.data.details;                         // Set the returned message for other methods.
                }
            })
            .catch(function (error: any) {
                console.log(error)
                if (!isNullOrUndefined(error.response.data.non_field_errors)) {
                    vm.status_response = error.response.data.non_field_errors[0]    // Display the returned non field error.
                } else {
                    vm.status_response = `An error occurred while setting ${vm.selected_method.name} method. Please try a different method.` // Otherwise display a generic error.
                }
            });
    }


    // Will login the user if doing a typical login, or will confirm the MFA activation if they activated a new method. (both return a token to login)
    onLogin() {

        // Only automatically login when the MFA code length reaches its required length. Note: onLogin() is called every time the OTP code changes via a watcher.
        if (this.otp_code.length !== this.otp_length) { return };

        this.isLoggingIn = true;

        var method;
        if (this.isActivating) {
            // Need to confirm activation method (which also completes the login process)
            method = this.confirm({ 'code': this.otp_code.toLowerCase(), 'method': this.selected_method.id })
        } else {
            // Just use MFA login
            method = this.login({ 'ephemeral_token': this.auth.mfa_current.ephemeral_token, 'code': this.otp_code.toLowerCase() })
        }

        // Perform login
        var vm = this;
        method.then(function (response: any) {
            if (vm.datasets.nav === 'SupplyChains') {
                vm.$router.push("/benchmark")
            } else if (vm.$route.query.redirect) { // Go to /benchmark on successful login, if this.datasets.nav === 'SupplyChains'
                vm.$router.push(vm.$route.query.redirect as string);
            } else {
                vm.$router.push("/")                                        // Else go to root on successful login.
            }

        }).catch(function (error: any) {
            console.log(error)                                              // Otherwise display the error.
            if (!isNullOrUndefined(error.response)) {
                if (!error.response.data.code) {
                    vm.login_error = error.response.data[0];               // Change primary method error for invalid method.
                } else {
                    vm.login_error = error.response.data.code[0];          // Confirm activation error for invalid code.
                }
            } else {
                vm.login_error = error;                                     // MFA login error for invalid code.
            }
        });

    }

}




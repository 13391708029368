import { MutationTree } from 'vuex';
import { FiltersState, IFilterValue, IFilterEnabled, IFilterParams } from './types';


export const mutations: MutationTree<FiltersState> = {
    setFilterValue(state, payload: IFilterValue) {
        state.filter_types[payload.filter].value = payload.value;
    },
    setFiltersNotDirty(state) {
        state.isDirty = false;
    },
    setFiltersDirty(state) {
        state.isDirty = true;
    },
    setFiltersDisabled(state, payload: boolean) {
        state.disabled = payload;
    },
    setFiltersVisible(state, payload: boolean) {
        state.visible = payload;
        state.disabled = !payload;
    },
    setFilterEnabled(state, payload: IFilterEnabled) {
        state.filter_types[payload.filter].enabled = payload.value;
    },
    setFilterParams(state, payload: IFilterParams) {
        state.filter_types[payload.filter].item_list_params = payload.params;
    },
    setQueryParams(state, queryParams: any) {
        state.queryParams = queryParams;
    },
};
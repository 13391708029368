import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { TablesState } from './types';
import { RootState } from '../types';
import { endpoints } from '../../endpoints'


export const state: TablesState = {
    reports: {
        Links: { data: [], isLoading: false, isDownloading: false },
        Commodities: { data: [], isLoading: false, isDownloading: false },
        Movements: { data: [], isLoading: false, isDownloading: false },
    },
    isStandAlone: false,
    syncWithMap: false,
    isDownloadDisabled: true,
    queryParams: {}
};

const namespaced: boolean = true;

export const tables: Module<TablesState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
import { ActionTree } from 'vuex';
import { FiltersState, IFilterValue, IFilterEnabled, IFilterParams} from './types';
import { RootState } from '../types';


export const actions: ActionTree<FiltersState, RootState> = {
    setFilterValue({ commit }, payload: IFilterValue): any {
        commit('setFilterValue', payload);
    },
    setFilterEnabled({ commit }, payload: IFilterEnabled): any {
        commit('setFilterEnabled', payload);
    },
    setFilterParams({ commit }, payload: IFilterParams): any {
        commit('setFilterParams', payload);
    },
    setFiltersNotDirty({ commit }): any {
        commit('setFiltersNotDirty');
    },
    setFiltersDisabled({ commit }, payload: boolean): any {
        commit('setFiltersDisabled', payload);
    },
    setFiltersVisible({ commit }, payload: boolean): any {
        commit('setFiltersVisible', payload);
    },
    setFiltersDirty({ commit }): any {
        commit('setFiltersDirty');
    },
    setQueryParams({ commit }, queryParams: any): any {
        commit('setQueryParams', queryParams);
    },
    removeUnusedFilters({ commit, state }) {
        for (var filterId in state.filter_types) {
            var filterObj = state.filter_types[filterId];
            if (filterObj.isSelectable) {
                var enabled = ((filterObj.value != null) && (filterObj.value.length > 0));
                commit('setFilterEnabled', { filter: filterId, value: enabled });
            }
        }
    },
    clearFilters({ commit, state }) {
        for (var filterId in state.filter_types) {
            commit('setFilterValue', { filter: filterId, value: [] });
        }
        commit('setFiltersNotDirty');
    }

};
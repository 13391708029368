











































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { mixins } from 'vue-class-component';
import Validation from './validation-mixin'

import { VueRecaptcha } from 'vue-recaptcha';

@Component({
    components: {
        VueRecaptcha
    }
})
export default class Register extends mixins(Validation) {

    @Action('auth/resetPasswordLink') resetPasswordLink: any;
    @Action('auth/verifyRecaptcha') verifyRecaptcha: any;

    status_message = '';
    status_type = 'info';

    // reCAPTCHA settings
    siteKey = "6LfYcFgpAAAAALOhebM4_a6mLJPu5vAalw5CkeIl"
    widget_id = null;

    loading = false;

    user: any = {};

    onSubmit() {
        this.status_message = '';
        this.loading = true;
        (this.$refs.recaptcha as any).reset(this.widget_id);
        (this.$refs.recaptcha as any).execute(this.widget_id);
    }

    onRecaptchaVerify(token: any) {
        console.log("onRecaptchaVerify");
        console.log(token);

        if (token.length > 0) {

            var vm = this;
            this.verifyRecaptcha(token)
                .then(function (response: any) {
                    console.log("reCAPTCHA verification successful!")
                    vm.onSendResetPasswordLink();
                })
                .catch(function (error: any) {
                    console.log("reCAPTCHA verification failed!")
                    console.log(error)
                    vm.loading = false;
                    var msg = 'An error has occurred. Try again shortly.';
                    if (error.response.data.recaptcha) {
                        msg = error.response.data.recaptcha[0];
                    }
                    vm.status_type = 'error';
                    vm.status_message = msg;
                }).finally(function () {
                    (vm.$refs.recaptcha as any).reset(vm.widget_id);
                });
        }
    }

    onRecaptchaRendered(widget_id: any) {
        this.widget_id = widget_id;
    }

    onRecaptchaExpired() {
        this.status_message = 'reCAPTCHA has expired.';
        this.status_type = 'error';
    }

    onRecaptchaError() {
        this.status_message = 'A reCAPTCHA error occurred.';
        this.status_type = 'error';
    }


    onSendResetPasswordLink() {

        var vm = this;
        vm.loading = true;
        this.resetPasswordLink(this.user).then(function (response: any) {
            console.log(response);
            vm.loading = false;
            vm.status_type = 'success';
            vm.status_message = response.data.detail;
        }).catch(function (error: any) {
            console.log(error);
            vm.loading = false;
            vm.status_type = 'error';
            if (error.response.status === 429) {
                vm.status_message = error.response.data.detail.replace('Request was throttled. Expected available in', 'Too many attempts. Try again in');
            } else {
                vm.status_message = error.response.data.detail;
            }
        });
    }
}

























































import { Component, Prop, Vue, Watch, Inject } from 'vue-property-decorator';
import { bus } from '@/pages/transitweb/main';

@Component({
    components: {
    }
})
export default class RoadClosureInfo extends Vue {

    @Prop() private value!: boolean;

    onClose() {
        bus.$emit('road_closure_sources', false);
    }

}































































































































































































































































































































































































































































































































//    import { Component, Prop, Vue } from 'vue-property-decorator';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { isNullOrUndefined } from 'util';
import Links from './Links.vue';
import { State, Action, Getter } from 'vuex-class';

import variables from '@/pages/app'

//import { bus } from '../pages/benchmarking/App.vue'
//bus.$emit('changeIt', 'changed header');

@Component
export default class NavBar extends Links {
    @Prop() private commodityFilter!: string;
    @Prop() private supplyChainFilter!: any;
    @Prop() private spatialFilterFrom!: any;
    @Prop() private spatialFilterTo!: any;
    @Prop() private showTitle!: boolean;
    @Getter('auth/isAdmin') isAdmin?: boolean;
    @Getter('auth/isAllowedSensitiveInfo') isAllowedSensitiveInfo!: boolean;
    version: string = variables.current_version;
    mode_filter: string[] = ['road', 'rail']
    mode_items: string[] =
        [
            'road',
            'rail',
        ]
    temporal_trips: boolean = false;
    snackbar: boolean = false;
    @Watch('mode_filter', { deep: false })
    modeChanged(val: any, oldVal: any) {
        if (!isNullOrUndefined(val)) {
            (Vue as any).gtm.trackEvent({
                event: 'gui',
                category: 'benchmark',
                action: 'Set Filter (Transport Mode)',
                label: JSON.parse(JSON.stringify(val)).sort().toString(),//
                //value: payload.value,
                noninteraction: true,
            });
            //console.log('event', 'gui', 'category', 'benchmark', 'action', 'Set Filter (Transport Mode)', 'label', val.toString())
        }
    }
    get commidityFilterGetter() { return this.commodityFilter }
    @Watch('commidityFilterGetter', { deep: false })
    commodityChanged(val: any, oldVal: any) {
        if (!isNullOrUndefined(val)) {
            (Vue as any).gtm.trackEvent({
                event: 'gui',
                category: 'benchmark',
                action: 'Set Filter (Commodity)',
                label: val.toString(),
                //value: payload.value,
                noninteraction: true,
            });
            //console.log('event', 'gui', 'category', 'benchmark', 'action', 'Set Filter (Commodity)', 'label', val.toString())
        }
    }
    @Watch('supplyChainFilter', { deep: false })
    supplyChainChanged(val: any, oldVal: any) {
        if (!isNullOrUndefined(val)) {
            (Vue as any).gtm.trackEvent({
                event: 'gui',
                category: 'benchmark',
                action: 'Set Filter (Supply Chain)',
                label: val.origin_enterprise_type + " - " + val.dest_enterprise_type,
                //value: payload.value,
                noninteraction: true,
            });
            //console.log('event', 'gui', 'category', 'benchmark', 'action', 'Set Filter (Supply Chain)', 'label', val.toString())
        }
    }

    // https://vuetifyjs.com/en/features/breakpoints/#breakpoint-service
    get isMobile() {
        return this.$vuetify.breakpoint.name == 'xs'
    }
    get isTablet() {
        return this.$vuetify.breakpoint.name == 'sm' || this.$vuetify.breakpoint.name == 'md'
    }
    get isWorkstation() {
        return this.$vuetify.breakpoint.name == 'lg' || this.$vuetify.breakpoint.name == 'xl'
    }

    get operationSymbol() {
        var txt = document.createElement("textarea");
        if (!isNullOrUndefined(this.supplyChainFilter)) {
            if (this.supplyChainFilter.enterprise_type_operation == "Intersection") {
                txt.innerHTML = "(" + "&#8745;" + ")"
            }
            if (this.supplyChainFilter.enterprise_type_operation == "Union") {
                txt.innerHTML = "(" + "&#8746;" + ")"
            }
        }
        return txt.value;
    }

    get commodityFilterItems() {
        if (!isNullOrUndefined(this.commodityFilter)) {
            return [this.commodityFilter]
        }
        else {
            return [];
        }
    }
    get commodityFilterValue() {
        return this.commodityFilter
    }
    set commodityFilterValue(value) {
        // do nothing because there is an event to act to changes
    }
    get supplyChainItems() {
        var ret = []
        if (!isNullOrUndefined(this.supplyChainFilter)) {
            if (this.supplyChainFilter.orig_enterprise_type != "") {
                ret.push('From: ' + this.supplyChainFilter.orig_enterprise_type)
            }
            if (this.supplyChainFilter.dest_enterprise_type != "") {
                ret.push('To: ' + this.supplyChainFilter.dest_enterprise_type)
            }
        }
        return ret;
    }

    get supplyChainFilterValue() {
        return this.supplyChainFilter
    }
    set supplyChainFilterValue(value) {
        // do nothing because there is an event to act to changes
    }

    get spatialFilterItemsFrom() {
        var ret = []
        if (!isNullOrUndefined(this.spatialFilterFrom) && this.spatialFilterFrom.hasOwnProperty('text')) {
            ret.push('From: ' + this.spatialFilterFrom.text)
        }
        return ret;
    }
    get spatialFilterItemsTo() {
        var ret = []
        if (!isNullOrUndefined(this.spatialFilterTo) && this.spatialFilterTo.hasOwnProperty('text')) {
            ret.push('To: ' + this.spatialFilterTo.text)
        }
        return ret;
    }
    closeSpatialChip(e: string) {
        this.$emit('spatial_filter_close_chip', e);
    }
    closeSupplyChainChip(e: any) {
        this.$emit('supplychain_filter_close_chip', e);
    }
    clearSupplyChainFilter(e: any) {
        this.$emit('supplychain_filter_close');
    }
    clearCommodityFilter() {
        this.$emit('commodity_filter_close');
    }
    modeOnChange(e: any) {
        this.$emit('mode_filter_change', e);
    }
    closeModeChip(e: any) {
        var idx = this.mode_filter.indexOf(e)
        this.mode_filter.splice(idx, 1)
        this.$emit('mode_filter_change', this.mode_filter);
    }
    temporalTripsOnChange(e: any) {
        this.$emit('temporal_trips_change', e);
    }
}

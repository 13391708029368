import { GetterTree } from 'vuex';
import { MapState } from './types';
import { RootState } from '../types';
import { isNullOrUndefined } from 'util';

export const getters: GetterTree<MapState, RootState> = {

    isInspecting(state): boolean {
        return !(isNullOrUndefined(state.marker));
    },
    isLayerVisible(state): any {
        return (layerId: string) => {
            return !(state.hiddenLayers.includes(layerId));
        }
    },
};
export interface ITableLoading { table: string, value: boolean }
export interface ITableData { table: string, value: any }
export interface ITable { table: string, url?: string }

export enum TableType {
    Links = "Links",
    Commodities = "Commodities",
    Movements = "Movements"
}

export interface TablesState {
    reports: {
        Links: TableState,
        Commodities: TableState,
        Movements: TableState,
        [key: string]: TableState;
    },
    isStandAlone: boolean;
    syncWithMap: boolean;
    isDownloadDisabled: boolean;
    queryParams: any;
}

export interface TableState {
    data: any,
    isLoading: boolean,
    isDownloading: boolean,
}
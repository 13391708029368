





























































import { isNullOrUndefined } from 'util';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

@Component
export default class VerifyRegistration extends Vue {

    @Action('auth/verifyRegistration') verifyRegistration: any;

    status_message = 'Veryifying user account...';
    status_type = 'info';

    created() {

        // Check that the required parameters have been provided
        if (isNullOrUndefined(this.$route.query.signature)) {
            this.status_message = "Signature query parameter is required";
            this.status_type = "error";
        }
        if (isNullOrUndefined(this.$route.query.timestamp)) {
            this.status_message = "Timestamp query parameter is required";
            this.status_type = "error";
        }
        if (isNullOrUndefined(this.$route.query.user_id)) {
            this.status_message = "User ID query parameter is required";
            this.status_type = "error";
        }


        if (this.status_type !== "error") {

            var data = {
                user_id: this.$route.query.user_id,
                timestamp: this.$route.query.timestamp,
                signature: this.$route.query.signature
            }

            var vm = this;
            this.verifyRegistration(data).then(function (response: any) {
                //console.log(response);
                vm.status_type = 'success';
                vm.status_message = response.data.detail;

            }).catch(function (error: any) {
                //console.log(error);
                vm.status_type = 'error';
                if (error.response.status === 429) {
                    vm.status_message = error.response.data.detail.replace('Request was throttled. Expected available in', 'Too many attempts. Try again in');
                } else {
                    vm.status_message = error.response.data.detail;
                }
            });

        }



    }
}








import { Prop, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component

export default class Links extends Vue {

    trackClick(label: string) {
        (Vue as any).gtm.trackEvent({
            event: 'gui',
            category: 'links',
            action: 'Link Clicked',
            label: label,
            //value: payload.value,
            noninteraction: true,
        });
        //console.log('Link Clicked', label)
    }

    provideFeedback() {
        (window.location as any) = "mailto:support@transit.csiro.au?subject=Supply Chain Benchmarking Dashboard Feedback&body=Please tell us about your experience...";
        this.trackClick('Provide Feedback')
    }

    about() {
        (window as any).open("https://www.csiro.au/TRANSIT");
        this.trackClick('About')
    }

    //If this.showTitle = true, display metadata for the public version of SCBD which excludes restricted commodities (in commodities table, benchmark = 0)
    metadata() {
        this.$props.showTitle ? (window as any).open('./docs/commodity_metadata_bm.xlsx') : (window as any).open('./docs/commodity_metadata.xlsx');
        this.trackClick('Commidity Metadata');
    }

    news_articles() {

        // Doco on querying the Google Search API here: https://www.google.com/support/enterprise/static/gsa/docs/admin/current/gsa_doc_set/xml_reference/request_format.html

        (window as any).open("https://www.google.com/search?q=%22Supply+Chain+Benchmarking+Dashboard%22&tbs=sbd:1&tbm=nws");
        this.trackClick('News Articles')
    }

    publications() {

        // Doco on querying the Google Search API here: https://www.google.com/support/enterprise/static/gsa/docs/admin/current/gsa_doc_set/xml_reference/request_format.html

        (window as any).open("https://scholar.google.com.au/scholar?hl=en&as_sdt=0,5&q=%E2%80%9CTransport+Network+Strategic+Investment+Tool%E2%80%9D");
        this.trackClick('Publications')
    }

    faq() {
        (window as any).open('./docs/faq_transit_model_bm.pdf');
        this.trackClick('FAQ')
    }

    user_guide() {
        (window as any).open('./docs/user_guide_dashboard.pdf');
        this.trackClick('User Guide')
    }

    quick_start() {
        (window as any).open('https://www.freightaustralia.gov.au/sites/default/files/documents/Dashboard%E2%80%94quick-start-user-guide.pdf');
        this.trackClick('Quick Start')
    }

    use_cases() {
        (window as any).open('https://www.freightaustralia.gov.au/sites/default/files/documents/Dashboard%E2%80%94Task-specific-user-guide.pdf');
        this.trackClick('Use Cases')
    }

}

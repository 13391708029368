import { ActionTree } from 'vuex';
import axios from 'axios';
import { DatasetState } from './types';
import { RootState } from '../types';
import { endpoints } from '../../endpoints'


export const actions: ActionTree<DatasetState, RootState> = {
    getDatasets({ commit }): any {
        axios({
            url: endpoints.datasetsUrl
        }).then((response) => {
            var datasets = [];
            for (var i = 0; i < response.data.length; i++) {
                datasets.push(response.data[i].pk);
            }

            commit('datasetsLoaded', datasets);
        }, (error) => {
            console.log(error);
        });
    },
    setDataset({ commit }, dataset: string): any {
        commit('setDataset', dataset);
    },
    setDatasetType({ commit }, datasetType: string): any {
        commit('setDatasetType', datasetType);
    },
    setNav({ commit }, nav: string): any {
        commit('setNav', nav);
    },


};
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"elevation-20 mb-2 rounded-card"},[_c('v-toolbar',{staticClass:"toolbar",attrs:{"dense":"","dark":"","color":"#F7455D"}},[_c('v-icon',[_vm._v("mdi-calendar-clock")]),_c('span',{staticClass:"icon-spacer"}),_c('v-toolbar-title',{staticClass:"font-weight-regular"},[_vm._v("Events")])],1),_c('div',{staticClass:"filters-content"},[_c('v-row',{staticClass:"date-selector",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":true,"min-width":"auto","nudge-bottom":"25"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-combobox',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar-month","label":"Date","readonly":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"#F7455D","text-color":"white","small":""}},[_vm._v(_vm._s(_vm.getFormattedDate))])]}}],null,true),model:{value:(_vm.event_date),callback:function ($$v) {_vm.event_date=$$v},expression:"event_date"}},'v-combobox',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"header-color":"#0398bf","width":"335","elevation":"20","allowed-dates":_vm.allowedDates,"items":_vm.events.event_date_items,"color":"#F7455D","event-color":"black"},on:{"input":_vm.onDateChange},model:{value:(_vm.event_date),callback:function ($$v) {_vm.event_date=$$v},expression:"event_date"}})],1)],1),_c('v-col',{attrs:{"sm":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":"","color":"#0398bf"},on:{"click":_vm.downloadImpactReport}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)]}}])},[_c('span',[_vm._v("Download TraNSIT supply chain impact report for the selected date")])])],1),(_vm.isAllowedSensitiveInfo || _vm.isScenarioUser)?_c('v-col',{attrs:{"sm":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":"","color":"#0398bf"},on:{"click":_vm.createScenario}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-link-plus")])],1)]}}],null,false,1637993017)},[_c('span',[_vm._v("Create a custom scenario using the closed roads for the selected date")])])],1):_vm._e()],1),_c('v-select',{key:"Layers",staticClass:"selector",attrs:{"prepend-inner-icon":"mdi-layers-outline","items":_vm.event_layer_items,"label":"Layer","dense":"","menu-props":{ offsetY: true }},on:{"input":_vm.onLayerChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"#F7455D","text-color":"white","small":""}},[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.event_layer),callback:function ($$v) {_vm.event_layer=$$v},expression:"event_layer"}}),_vm._t("footer")],2)],1),_c('RoadClosureInfo',{model:{value:(_vm.road_closure_sources),callback:function ($$v) {_vm.road_closure_sources=$$v},expression:"road_closure_sources"}}),_c('v-snackbar',{staticClass:"report-not-available",attrs:{"timeout":8000,"vertical":true,"elevation":"24","absolute":"","centered":"","top":"","light":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"#F7455D","text":"","variant":"text","title":"Close sources"},on:{"click":function($event){_vm.report_not_ready_alert = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.report_not_ready_alert),callback:function ($$v) {_vm.report_not_ready_alert=$$v},expression:"report_not_ready_alert"}},[_c('v-toolbar',{staticClass:"toolbar",attrs:{"dense":"","dark":"","color":"#F7455D"}},[_c('v-icon',[_vm._v("error_outline")]),_c('span',{staticClass:"icon-spacer"}),_c('v-toolbar-title',{staticClass:"font-weight-regular"},[_vm._v("Report Unavailable")])],1),_c('div',{staticClass:"pa-5 pb-0"},[_c('p',[_vm._v("Sorry, the report is not yet available for "+_vm._s(_vm.getFormattedDate))]),_c('p',[_vm._v("Please check back again soon!")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
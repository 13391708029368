





























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { bus } from '@/pages/transitweb/main'
import Speech from '@/components/assistant/Speech.vue';
import { AssistantState } from '@/store/assistant/types';


@Component({
    components: {
        Speech
    }
})
export default class Message extends Vue {

    @Prop() private name!: string;
    @Prop() private subject!: string;
    @Prop() private messageRaw!: string;
    @Prop() private images!: any;
    @Prop() private role!: string;
    @Prop() private date!: number;
    @Prop() private status!: string;
    @Prop() private audio_disabled!: boolean;

    @Prop() private color!: string;

    @State('assistant') assistant!: AssistantState;
    @Action('assistant/setQuestion') setQuestion: any;
    @Action('assistant/askQuestion') askQuestion: any;
    @Getter('assistant/assistantName') assistantName!: string;

    message_content = ''

    mounted() {
        this.message_content = (this.$refs.message as any).textContent
    }

    playAudio() {
        (this.$refs.speech as any).onPlaySpeech();
    }

    copyToClipboard() {
        navigator.clipboard.writeText(this.message_content);
    }

    get isAssistant() {
        return this.name == this.assistantName;
    }

    editQuestion() {
        this.setQuestion(this.messageRaw);
        bus.$emit('focus_question');
    }

  }


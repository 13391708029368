import { MutationTree } from 'vuex';
import { TablesState, TableState, ITableLoading, ITableData } from './types';
import Vue from 'vue';


export const mutations: MutationTree<TablesState> = {
    setIsStandAlone(state, isStandAlone: boolean) {
        state.isStandAlone = isStandAlone;
    },
    setSyncWithMap(state, syncWithMap: boolean) {
        state.syncWithMap = syncWithMap;
    },
    setIsDownloadDisabled(state, isDownloadDisabled: boolean) {
        state.isDownloadDisabled = isDownloadDisabled;
    },
    setTableLoading(state, payload: ITableLoading) {
        state.reports[payload.table].isLoading = payload.value;
    },
    setTableDownloading(state, payload: ITableLoading) {
        state.reports[payload.table].isDownloading = payload.value;
    },
    setQueryParams(state, queryParams: any) {
        state.queryParams = queryParams;
    },
    setTableData(state, payload: ITableData) {
        state.reports[payload.table].data = payload.value;
    },
};
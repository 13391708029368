

































































































import { isNullOrUndefined } from 'util';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { mixins } from 'vue-class-component';
import Validation from './validation-mixin'

@Component
export default class ResetPassword extends mixins(Validation) {

    @Action('auth/resetPassword') resetPassword: any;
    @Action('auth/resetPasswordLink') resetPasswordLink: any;

    status_message = '';
    status_type = 'info';
    resend_link = false;
    showPassword = false;
    showPasswordConfirm = false;

    loading = false;

    onChangePassword() {

        this.status_message = "";
        this.status_type = "info";
        this.resend_link = false;

        if ((this.$refs.form as any).validate()) {

            // Check that the required parameters have been provided
            if (isNullOrUndefined(this.$route.query.signature)) {
                this.status_message = "Signature query parameter is required";
                this.status_type = "error";
            }
            if (isNullOrUndefined(this.$route.query.timestamp)) {
                this.status_message = "Timestamp query parameter is required";
                this.status_type = "error";
            }
            if (isNullOrUndefined(this.$route.query.user_id)) {
                this.status_message = "User ID query parameter is required";
                this.status_type = "error";
            }


            if (this.status_type !== "error") {

                var data = {
                    user_id: this.$route.query.user_id,
                    timestamp: this.$route.query.timestamp,
                    signature: this.$route.query.signature,
                    password: this.password.password
                }

                var vm = this;
                vm.loading = true;
                this.resetPassword(data).then(function (response: any) {
                    //console.log(response);
                    vm.status_type = 'success';
                    vm.status_message = response.data.detail;

                }).catch(function (error: any) {
                    //console.log(error);
                    if (!isNullOrUndefined(error.response.data.detail)) {
                        if (error.response.data.detail === "Signature expired") {
                            vm.resend_link = true;
                        } else if (error.response.status === 429) {
                            vm.status_type = 'error';
                            vm.status_message = error.response.data.detail.replace('Request was throttled. Expected available in', 'Too many attempts. Try again in');
                        } else {
                            vm.status_type = 'error';
                            vm.status_message = error.response.data.detail;
                        }
                    } else {
                        vm.status_type = 'error';
                        if (error.response.headers['content-type'] == 'application/json') { // Sends html error on say e-mails.
                            for (const field of Object.keys(error.response.data)) {
                                vm.status_message = error.response.data[field][0];
                                break;
                            }
                        }
                    }

                }).finally(function () { vm.loading = false; });
            }
        }
    }

    onSendResetPasswordLink() {
        var vm = this;
        vm.loading = true;
        this.resetPasswordLink({ login: this.$route.query.email }).then(function (response: any) {
            console.log(response);
            vm.loading = false;
            vm.status_type = 'success';
            vm.status_message = response.data.detail;
        }).catch(function (error: any) {
            console.log(error);
            vm.loading = false;
            vm.status_type = 'error';
            if (error.response.status === 429) {
                vm.status_message = error.response.data.detail.replace('Request was throttled. Expected available in', 'Too many attempts. Try again in');
            } else {
                vm.status_message = error.response.data.detail;
            }
        });
    }
}



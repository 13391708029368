

















































import { Component, Prop, Vue, Watch, Inject } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { Layers } from '@/store/map/types';

@Component({
    components: {
    }
})
export default class EnterpriseLegendEvents extends Vue {

    @Action('map/setLayerVisibility') setLayerVisibility: any;
    @Getter('map/isLayerVisible') isLayerVisible!: any;  // Returns an anonymous function

    Layers: any = Layers;

    toggleVisibility(layer: string) {
        this.setLayerVisibility({
            layer: layer,
            value: !this.isLayerVisible(layer)
        });
    }
}



























import { GChart } from 'vue-google-charts';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    components: {
        GChart,
    }
})

export default class BlockedCommodityChart extends Vue{

    @Prop() private properties!: any;

    data: any[] = [];

    created() {
        this.data = this.chartData(this.properties.commodity_tonne, this.properties.total_tonnes);
    }

    get chartOptions() {

        return ({

            bars: 'horizontal', // Required for Material Bar Charts.
            series: {
                0: { axis: 'tonnes' }, // Bind series 0 to an axis named tonnes
            },
            axes: {
                x: {
                    tonnes: {
                        side: 'bottom', label: "Weekly tonnes"
                    }
                },
                y: {
                    0: { side: 'bottom', label: "" }
                }
            },
            hAxis: {
                format: 'short'
            },
            tooltip: { isHtml: true},
            height: 200,
            width: 300,
            legend: { position: 'none' },
        })
    };


    chartData(commodity_tonne: any, totaltonnes: any) {

        var data = [];
        data.push(['Commodity', 'Weekly tonnes']);
        var objs = JSON.parse(commodity_tonne);

        // Descending sort
        objs.sort((a: any, b: any) => b.tonnes - a.tonnes);

        for (var i = 0; i < objs.length; i++) {
            data.push([objs[i].commodity, objs[i].tonnes])
        };

        data.push(['TOTAL', totaltonnes]);

        return data
    }
}


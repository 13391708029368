import { MutationTree } from 'vuex';
import { AssistantState, MessageState } from './types';

export const mutations: MutationTree<AssistantState> = {

    setMessages(state, payload: MessageState[]) {
        state.messages = payload;
    },

    setProgressiveMessage(state, payload: MessageState) {
        state.progressiveMessage = payload;
    },

    setIsStandAlone(state, isStandAlone: boolean) {
        state.isStandAlone = isStandAlone;
    },

    setSyncWithMap(state, syncWithMap: boolean) {
        state.syncWithMap = syncWithMap;
    },

    setPlayAudioResponses(state, playAudioResponses: boolean) {
        state.playAudioResponses = playAudioResponses;
    },

    addMessage(state, payload: MessageState) {
        payload.date = Date.now();
        state.messages.push(payload);
    },

    setQuestion(state, payload: string) {
        state.question = payload;
    },

    setSubject(state, payload: string) {
        state.subject = payload;
    },

    setLoading(state, loading: boolean) {
        state.loading = loading;
    },

}
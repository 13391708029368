













































































import { Component, Prop, Vue, Watch, Inject } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import buildUrl from 'build-url';
import { isNullOrUndefined } from 'util';
import { endpoints } from "@/endpoints";

import { DatasetState } from '@/store/datasets/types';


@Component({
    components: {
    }
})
export default class ContextReports extends Vue {

    @State('datasets') datasets!: DatasetState;

    // Month of Year Selector
    month_items: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    context_month: string = this.currentMonth;

    // LGA Selector
    lga_items: string[] = [];
    context_lga: any = null;

    // Can the report now be opened?
    isDownloadDirty: boolean = false;

    // Are the Context Reports expanded?
    show_context_reports: boolean = false;

    get dataset(): any {
        return this.datasets.dataset;
    }

    // Get the current month for the Month of Year Selector.
    get currentMonth(): string {
        return new Date().toLocaleString('default', { month: 'long' });
    }


    mounted() {

        Vue.nextTick(() => { // Note: needs to be run on next tick so that authentication can complete.

            //load the list of LGAs

            var vm = this;
            Vue.axios({
                url: buildUrl(endpoints.boundariesUrl(this.dataset), { queryParams: { layer: 'LGA' } })
            }).then((response: any) => {
                vm.lga_items = response.data;
            }, (error: any) => {
                console.log(error);
            });

        })
    }


    downloadContextReport() {

        // Generate signed URL to access context report from Google Storage Bucket

        Vue.axios({
            url: buildUrl(endpoints.contextReportUrl(this.context_month, this.context_lga))
        }).then((response: any) => {

            if (!isNullOrUndefined(response.data)) {
                var signed_url: any = response.data;
                (window as any).open(signed_url);
            }

        }, (error: any) => {
            console.log(error);
        });
        this.isDownloadDirty = false;

        //(window as any).open('./docs/acs_context_reports/' + this.context_month + '/' + this.context_lga + '.pdf');
    }


    onMonthChange(e: any) {
        if (!isNullOrUndefined(this.context_lga)) {
            this.isDownloadDirty = true
        };
    }


    onLGAChange(e: any) {
        this.isDownloadDirty = true;
    }


}



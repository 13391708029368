

























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Task from '@/components/tasks/Task.vue';
import { TaskStatus } from './types'

import { endpoints } from "@/endpoints";

import { bus } from '@/pages/transitweb/main'

@Component({
    components: {
        Task,
    }
})
export default class Tasks extends Vue {

    // Dummy tasks to initialize with!
    init_dummy_tasks: any = [
        { task_id: 'HSDKDGJSJD283F', task_name: 'Close Pacific Motorway @ Beenleigh', status: TaskStatus.Complete, start_time: this.subtractMinutes(Date.now(), 100), end_time: this.subtractMinutes(Date.now(), 41), outputs: {} },
        { task_id: 'ASDJFDSF35464D', task_name: 'Close Bruce Highway near Gympie', status: TaskStatus.Running, start_time: this.subtractMinutes(Date.now(), 15), outputs: {} },
        { task_id: 'VCBUOWERGN345D', task_name: 'Close movements between Higgins Farm & McFallan Fresh Fruits', status: TaskStatus.Failed, start_time: this.subtractMinutes(Date.now(), 9), end_time: this.subtractMinutes(Date.now(), 5), outputs: {} },
        { task_id: 'GJHKDHG567FDFF', task_name: 'Close 3 segments on Bondarenco Highway', status: TaskStatus.Complete, start_time: this.subtractMinutes(Date.now(), 80), end_time: this.subtractMinutes(Date.now(), 35), outputs: {} },
        { task_id: '32456WGHFDFGDW', task_name: 'Close segments from Knapp St/Price Rd intersection to Cook St', status: TaskStatus.Cancelled, start_time: this.subtractMinutes(Date.now(), 20), end_time: this.subtractMinutes(Date.now(), 5), outputs: {} },
        { task_id: 'DHGJFDS45637GG', task_name: 'Close Boulaire LGA', status: TaskStatus.Running, start_time: this.subtractMinutes(Date.now(), 35), outputs: {} },
        { task_id: 'VCBNHGDRET444F', task_name: 'Close Vleeshouwers Lane', status: TaskStatus.Submitted, start_time: this.subtractMinutes(Date.now(), 75), end_time: this.subtractMinutes(Date.now(), 41), outputs: {} },
    ]


    @Prop() private task_list_open!: boolean;
    //tasks: any[] = this.init_dummy_tasks;
    tasks: any[] = [];
    taskListTimer: any;
    taskAttempts = 0;
    taskInterval = 1

    loading = false;

    get runningTasks() {
        return this.tasks.filter((task) => task.status == TaskStatus.Submitted || task.status == TaskStatus.Running)
    }

    @Watch('task_list_open')
    onDialogPropChanged(val: boolean, oldVal: boolean) {
        this.task_list_dialog = val
    }

    created() {

        bus.$on('add_task', (e: any) => {
            let tasksCopy = [...this.tasks]
            tasksCopy.unshift(e);
            this.tasks = tasksCopy;

            this.setRapidUpdates()
        })
        bus.$on('refresh_tasks', (e: any) => {
            this.getTasksForUser()
        })
    }


    setRapidUpdates() {
        this.taskAttempts = 0;
        this.taskInterval = 5;
        console.log(`Setting task timer to ${this.taskInterval}s`);
    }


    checkRelaxingOfRapidUpdates() {

        if (this.runningTasks.length > 0) {

            // If there are tasks running, set a timer to fetch the tasks again soon.
            this.taskListTimer = setTimeout(this.getTasksForUser, 1000 * this.taskInterval) // Continue getting tasks.
            this.taskAttempts = this.taskAttempts + 1;

            // Relax the timer interval after so many attempts.
            if (this.taskAttempts >= 12) {
                this.taskInterval = 60
                console.log(`Relaxing task timer to ${this.taskInterval}s`);
            }
        } else {
            // Clear the timer if no tasks are running.
            console.log('Clearing task timer')
            clearInterval(this.taskListTimer)
        }
    }


    task_list_dialog: boolean = false;
    @Watch('task_list_dialog')
    onDialogChanged(val: boolean, oldVal: boolean) {

        // On dialog open get list of jobs for user from Google Cloud Run and the poll API every 1 minute?

        if (val) {
            this.setRapidUpdates()
            this.getTasksForUser()
        } else {
            clearInterval(this.taskListTimer)                                    // Stop updating tasks when dialog is closed.
        }
        bus.$emit('task_list_dialog', val);
    }


    // Helper function to create some dummy start/end times for tasks.
    subtractMinutes(date: number, minutes: number) {
        var date_obj = new Date(date)
        date_obj.setMinutes(date_obj.getMinutes() - minutes);
        return date_obj;
    }


    // Refresh the task list.
    getTasksForUser() {
        console.log('Retrieving tasks...')
        this.loading = true;

        Vue.axios({
            url: endpoints.listTasksUrl()
        }).then((response: any) => {
            //console.log(response);

            // Parse the dates.
            for (var task of response.data) {
                task.create_time = task.create_time ? new Date(task.create_time) : task.create_time
                task.start_time = task.start_time ? new Date(task.start_time) : task.start_time
                task.end_time = task.end_time ? new Date(task.end_time) : task.end_time
            }
            this.tasks = response.data;

        }, (error: any) => {
            console.log(error);
        }).finally(() => {
            //console.log('Task list finished');
            // See if the update interval should be relaxed.
            this.checkRelaxingOfRapidUpdates()
            this.loading = false;
        });
    }

    onClose() {
        bus.$emit('task_list_dialog', false);
    }


}


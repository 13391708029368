










































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { bus } from '@/pages/transitweb/main'
import { MapState } from '@/store/map/types';

import { GChart } from 'vue-google-charts'

import InfoPanelDashboard from './InfoPanelDashboard.vue';
import InfoPanelCharts from './InfoPanelCharts.vue';
import CO2 from './CO2.vue';

@Component({
    components: {
        GChart,
        CO2,
        InfoPanelDashboard,
        InfoPanelCharts
    }
})
export default class InfoPanelRoad extends Vue {

    @Prop() private info!: any;

    @Prop() private chart_data!: any;
    @Prop() private hidecounts!: boolean;
    @Prop() private statisticType!: string;

    @State('map') mapstate!: MapState;

    @Getter('auth/isAllowedSensitiveInfo') isAllowedSensitiveInfo!: boolean;
    @Getter('auth/isScenarioUser') isScenarioUser!: boolean;

    @Action('map/setFlyTo') setFlyTo: any;

    get road(): any {
        return this.info || this.mapstate.featureOfInterest.properties;
    }

    private surfaceLookup: any = { 'true': 'Sealed', 'false': 'Unsealed' }
    private pbsLookup: any = {
        'ST': { level: 'Level 1', access: 'Semi-Trailer', maximum_length: 20, maximum_mass: 50.5 },
        'BD': { level: 'Level 2a', access: 'B-Double', maximum_length: 26, maximum_mass: 85 },
        'T1': { level: 'Level 3a', access: 'T1 Road Train', maximum_length: 36.5, maximum_mass: 110 },
        'T2': { level: 'Level 4a', access: 'T2 Road Train', maximum_length: 53.5, maximum_mass: 150 },
        'U': { level: 'Undefined', access: 'Not classified', maximum_length: 20, maximum_mass: 50 }
    }

    get getRoadSurface(): string {
        return this.surfaceLookup[this.road.surface];
    }

    get getAccess(): string {
        return this.pbsLookup[this.road.truck].access;
    }

    get getMaximumLength(): string {
        return this.pbsLookup[this.road.truck].maximum_length;
    }

    get getMaximumMass(): string {
        return this.pbsLookup[this.road.truck].maximum_mass;
    }

    get getPBSLevel(): string {
        if (!this.road) { return '' }
        return this.pbsLookup[this.road.truck].level;
    }

    get getName(): string {
        if (!this.road) { return '' }
        return this.road.street_name != null ? this.road.street_name : 'Unnamed Road'
    }

    onClose() {
        //this.expansionPanel = null;
        this.$emit('closed', this)
    }

    onCriticalLink() {
        var e: any = [this.road.link_id]
        //this.$emit('critical_link', 'critical_link', e, true)
        bus.$emit('add_special_filter', 'critical_link', e, true)
    }

    onCloseLink() {
        bus.$emit('scenario_close_segment', this.road);
    }

    onCloseRoad() {
        bus.$emit('scenario_close_road', this.road);
    }

    //onCloseArea() {
    //    bus.$emit('scenario_initialize_mapboxDraw');
    //}

    onAddRoadNameFilter() {
        var e: any = [this.road.street_name]
        bus.$emit('append_name_filter', 'roadname', e)
        this.onClose()
    }

}



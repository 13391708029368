
















































































































































































































































































































































import { Component, Prop, Vue, Watch, Inject } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { DatasetState } from '@/store/datasets/types';
import { EventsState } from '@/store/events/types';
import { DensityType } from '@/store/datasets/types';

import { bus } from '@/pages/transitweb/main'
import * as road_layers from '@/components/layers/roads';

import { Baseline, ScenarioAction, TaskType, PolygonOperator } from './types'

import buildUrl from 'build-url';

import { endpoints } from "@/endpoints";

import {
    MglMap,
} from 'vue-mapbox'

import MglVectorLayer from '@/components/MglVectorLayer.vue' // Use our custom version of the vector layer.
import { isNullOrUndefined } from 'util';

import { mixins } from 'vue-class-component';
import PolygonColors from './polygon-colors-mixin'

var mapboxgl = require('mapbox-gl');

@Component({
    components: {
        MglMap,
        MglVectorLayer,
    },

})

export default class ScenarioEditor extends mixins(PolygonColors) {

    @State('datasets') datasets!: DatasetState;
    @State('events') events!: EventsState;

    @Getter('auth/username') username!: string;
    @Getter('auth/isAdmin') isAdmin?: boolean;

    @Action('events/getEventDateItems') getEventDateItems: any;

    DensityType: any = DensityType;
    ScenarioAction: any = ScenarioAction;

    mapboxAccessToken: any = 'pk.eyJ1IjoiYm9uMTMyIiwiYSI6ImNqdXRhYmw1OTA1eGUzeW5yZGo3OWZmankifQ.RYbaSeGdz3Nq_hIGWXrpSw';
    //mapStyle = 'mapbox://styles/bon132/ckspigz2k5vsm18mjec6j8msb';
    mapStyle = 'mapbox://styles/mapbox/light-v10'; // style URL

    center = [134.0, -28.3];
    bounds_australia = [
        [60, -61], // Southwest coordinates
        [207, 20]  // Northeast coordinates
    ];

    task_name: string = '';
    scenario_actions: any = [];
    map_layer: any = [];
    valid: boolean = true;
    rules: any = {};
    confirmDialog: boolean = false;

    baseline: string = Baseline.SHORT_TERM_FORECAST;
    baseline_items: any[] =
        [
            { text: '7-Day Forecast', value: Baseline.SHORT_TERM_FORECAST, icon: './7days2.png', description: 'Produce a forecast for the next week, starting at todays date' },
            { text: 'Annual Baseline', value: Baseline.ANNUAL, disabled: true, icon: './365days2.png', description: 'Produce a forecast against the annual baseline' }
        ]


    polygon_operator = PolygonOperator.UNION
    polygon_operator_items: any[] =
        [
            {
                text: 'Union',
                value: 'union',
                description: 'Polygons are unioned (' + "&#8746;" + ')  with other scenario actions',
                icon: './union.svg'
            },
            {
                text: 'Intersection',
                value: 'intersection',
                description: 'Polygons are intersected (' + "&#8745;" + ') with other scenario actions',
                icon: './intersection.svg'
            },
        ]

    closeArea() {
        bus.$emit('scenario_initialize_mapboxDraw');
    }


    baseline_items_admin: any[] =
        [
            { divider: true },
            { header: 'Admin Reporting' },
            { text: '7DF for Jan', value: '0', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in January' },
            { text: '7DF for Feb', value: '1', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in February' },
            { text: '7DF for Mar', value: '2', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in March' },
            { text: '7DF for Apr', value: '3', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in April' },
            { text: '7DF for May', value: '4', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in May' },
            { text: '7DF for Jun', value: '5', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in June' },
            { text: '7DF for Jul', value: '6', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in July' },
            { text: '7DF for Aug', value: '7', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in August' },
            { text: '7DF for Sep', value: '8', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in September' },
            { text: '7DF for Oct', value: '9', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in October' },
            { text: '7DF for Nov', value: '10', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in November' },
            { text: '7DF for Dec', value: '11', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in December' },
        ];


    // Add in all months of the year, so admins can test that forecasts work all year round.
    get all_baseline_items() {
         return !this.isAdmin ? this.baseline_items : this.baseline_items.concat(this.baseline_items_admin);
    }

    event_date = '';

    loading_map: boolean = false;
    loading_create_task: boolean = false;

    error_msg: string = "";

    headers: any = [
        { text: 'Action', align: 'start', value: 'type', width: 140, },
        { text: 'Value', align: 'center', value: 'description', sortable: false, width: 300 },
        { text: 'Remove', align: 'center', value: 'actions', sortable: false },
    ];

    get dataset(): any {
        return this.datasets.dataset;
    }

    get densityType(): DensityType {
        return this.datasets.type;
    }

    get getFormattedDate(): string {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(this.event_date).toLocaleDateString("en-AU", options);
    }


    // The scenario definition.
    get scenario() {
        var scenario: {
            actions: { type: ScenarioAction, value: any, description: string }[],
            baseline: string,
            date: Date | undefined,
            polygon_operator: string,
        } = {
            actions: [],
            baseline: this.getBaseline,
            date: this.getForecastDate,
            polygon_operator: this.polygon_operator
        };

        for (const item of this.scenario_actions) {
            scenario.actions.push({
                type: item.type,
                value: item.value,
                description: item.description
            });
        }

        return scenario;
    }


    // Gets the forcasted date to use on a scenario.
    get getForecastDate() {

        var date = new Date();
        if (this.baseline == Baseline.SHORT_TERM_FORECAST) {
            return date;
        } else if (this.baseline == Baseline.ANNUAL) {
            return undefined;
        }
        else {
            // Adjust the month.
            var month = parseInt(this.baseline);
            date.setMonth(month);
            return date;
        }
    }


    // Gets the baseline parameter needed to run a scenario.
    get getBaseline() {
        var month = parseInt(this.baseline);
        return isNaN(month) ? this.baseline : Baseline.SHORT_TERM_FORECAST;
    }


    @Watch('scenario')
    onScenarioChanged(val: any, oldVal: any) {
        // Set task name automatically when going from no actions to 1 action.
        if (val.actions.length == 1 && (oldVal.actions.length <= 1 || this.task_name == '')) {
            this.task_name = val.actions[0].type + ' ' + val.actions[0].description;
        }
        // Clear task name if going from 1 to more actions.
        if (oldVal.actions.length == 1 && val.actions.length == 2) {
            this.task_name = '';
        }

        // Update inset map layer if actions applied or removed
        this.updateMapLayer(); 

        // Fit map to Australia if no actions applied.
        if (val.actions.length == 0) {
            this.task_name = '';
            this.$data.map.fitBounds(this.bounds_australia, {
                padding: 50, // Padding around the bounds
                //easing: //some animate function
            });
        }
    }


    created() {

        // Gets the dates for which closed road layers are available.
        this.getEventDateItems(this.dataset);

        bus.$on('scenario_close_segment', (e: any) => {
            var action = Object.assign({}, e);
            action['type'] = ScenarioAction.SEGMENT;
            action['value'] = e.link_id;
            action['description'] = e.link_id + ' (on ' + e.street_name + ')';

            this.addAction(action); // Check for duplicates and load data.
            bus.$emit('scenario_editor', true);

        })
        bus.$on('scenario_close_road', (e: any) => {
            var action = Object.assign({}, e);
            action['type'] = ScenarioAction.ROAD;
            action['value'] = e.street_name;
            action['description'] = e.street_name;

            this.addAction(action); // Check for duplicates and load data.
            bus.$emit('scenario_editor', true);
        })

        bus.$on('scenario_closed_roads', (e: any) => {
            var action = Object.assign({}, e);
            action['type'] = ScenarioAction.CLOSED_ROADS;
            action['value'] = e.event_date;
            action['description'] = e.event_date;

            this.addAction(action); // Check for duplicates and load data.
            this.event_date = e.event_date;
            bus.$emit('scenario_editor', true);
        })

        bus.$on('scenario_close_area', (e: any) => {

            var action = Object.assign({}, e);
            action['type'] = ScenarioAction.AREA;
            action['value'] = e;  // The entire polygon json
            action['description'] = 'Polygon';

            this.addAction(action); // Check for duplicates and load data.
            bus.$emit('scenario_editor', true);
        })

        bus.$on('scenario_load', (e: any) => {

            this.clearScenario();
            this.task_name = e.task_name;
            this.baseline = e.inputs.baseline;
            this.polygon_operator = e.inputs.polygon_operator

            for (var action of e.inputs.actions) {
                this.scenario_actions.push(action)
            }

            bus.$emit('scenario_editor', true);
        })
    };


    destroyed() {
        bus.$off('scenario_close_segment');
        bus.$off('scenario_close_road');
        bus.$off('scenario_close_area');
        bus.$off('scenario_close_task');
        bus.$off('scenario_load');
        bus.$off('scenario_remove_allPolygons');
    };


    //Vue expects a function
    allowedDates(val: string): boolean {
        return  this.events.event_date_items.includes(val);
    }


    onMapLoaded(e: any) {
        this.$data.map = e.map;
    }


    // Add scenario inputs and the Auth token in all Mapbox Tile requests.
    addScenarioInputsAndJWTToken(url: any, resourceType: any) { // Note: does not use Vue.axios that we have configured, so interceptors do not pick up 401 errors.
        if (resourceType == 'Tile' && !url.includes('mapbox.com')) {
            return {
                url: url,
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.access_token,
                    'X-Username': this.$store.state.auth.user.username,
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    inputs: this.scenario // Scenario inputs
                })
            }
        }
    }

   
    // Create a map layer for all actions.
    createLayer() {
        var tileurl = buildUrl(endpoints.taskClosedSegmentsLayerUrl(this.dataset), {
            queryParams: {
                polygon_operator: this.polygon_operator,
            }
        });

        var layer_id = 'inset-map-layer';
        var source_id = 'inset-map-layer-source';

        var layer = {
            layerId: layer_id,
            layer: road_layers.closedSegmentsStyle(layer_id, source_id),
            sourceId: source_id,
            source: {
                tiles: [tileurl], // Needs to be array of strings https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#vector-tiles
                tolerance: 40,
                buffer: 40,
            },
        }

        return layer
    };


    // Adds a new action to the scenario.
    addAction(action: any) {

        // Clear error mesage
        this.error_msg = "";

        var propertyToCompare = action.type === ScenarioAction.AREA ? 'id' : 'value'
        var existingAction = this.scenario_actions.find((obj: any) => obj[propertyToCompare] === action[propertyToCompare]);

        // If no duplicates detected, push the new action
        if (!existingAction) {

            this.scenario_actions.push(action);

        // If a duplicate polygon id is detected, remove and add it again - needed if geometry or location of an existing polygon changed
        } else if (action.type === ScenarioAction.AREA) {

            // Remove action by polygon id
            this.removeArrayById(action.id);

            // Add the new action with an updated to the scenario editor.
            this.scenario_actions.push(action);

        } else {
            // Show duplicate error message.
            this.error_msg = "The action selected already exists in this scenario."
        }

    };

    updateMapLayer() {

        this.loading_map = true;

        // Remove layer
        this.map_layer = [];

        // Only update map layer if actions exist
        if (this.scenario.actions.length > 0) {
            // Need to set a slight delay to assure that removal is visible, otherwise layers are not removed
            setTimeout(() => {
                // Create layer
                let layer = this.createLayer(); // Get vector tiles

                // Add map layer to map_layers - needed to control layer independently of scenarios (scenario editor)
                this.map_layer.push(layer);

                this.zoomToExtent(); // Get bounds and zoom to extent

                this.$data.map.resize(); // A workaround to resize the mini map so it is centered on Australia (needs to be applied with a slight delay)
            }, 100);

        //setTimeout(() => this.$data.map.resize(), 0); // A workaround to resize the mini map so it is centered on Australia

        }
        
    };

    // Remove an array from scenarios based on polygon id
    removeArrayById(polygonIdToRemove: any) {
        this.scenario_actions = this.scenario_actions.filter((innerArray: any) => innerArray.id != polygonIdToRemove);
        //this.map_layers = this.map_layers.filter((innerArray: any) => innerArray.id != polygonIdToRemove);
    };

    // Checks if array type 'Close Area' exists in scenario_actions: controls whether to disable Geometric overlap with polygons or not
    get findArrayCloseArea() {
        return this.scenario_actions.some(
            (action: any) => action.type === ScenarioAction.AREA
        );
    }


    // Deletes an action from the scenario.
    onDeleteAction(item: any, clearEventDate: boolean = true) {

        //Delete data
        var editedIndex = this.scenario_actions.indexOf(item);
        Vue.delete(this.scenario_actions, editedIndex);

        if (clearEventDate && item.type == ScenarioAction.CLOSED_ROADS) {
            this.event_date = '';
        }

        if (item.type == ScenarioAction.AREA) {
            bus.$emit('scenario_remove_polygon', item.value.id);
        }

        this.zoomToExtent(); // Get bounds and zoom to extent
        this.error_msg = "";
    };


    // Clears the scenario.
    clearScenario() {
        this.task_name = '';
        this.scenario_actions = [];
        this.map_layer = [];
        this.event_date = '';
        this.rules = {};
        this.polygon_operator = PolygonOperator.UNION; // Defaults polygon operator to 'union' after scenario is submitted.
    }


    // Closed road date has been changed.
    onDateChange(e: any) {
        if (e == null) {
            this.deleteClosedRoads(true);
        } else {
            this.deleteClosedRoads(false);
            this.addAction({ type: ScenarioAction.CLOSED_ROADS, value: e, description: e });
        }
    }


    // Delete all close road actions (used to only allow the user to submit 1 date representing closed roads).
    deleteClosedRoads(clearEventDate: boolean) {
        let closed_roads = this.scenario_actions.filter((scenario: any) => scenario.type == ScenarioAction.CLOSED_ROADS);
        for (const item of closed_roads) {
            this.onDeleteAction(item, clearEventDate);
        }
    }


    // Submits a scenario to the server for execution.
    onSubmitTask() {

        // Create task data object and send it to server.

        // Check the task name validation rules
        this.rules = {
            task_name_rules: [
                function (v: any) { return !!v || "Please enter your task name" }, // Checks if the task name is not empty
            ]
        }

        // Needed for validation of task name to work.
        this.$nextTick(() => {

            if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {

                this.loading_create_task = true;
                Vue.axios({
                    url: endpoints.createTaskUrl(this.dataset),
                    data: {
                        task_type: TaskType.Scenario,
                        //task_type: TaskType.Scenario_test, // This is to run a test container.
                        task_name: this.task_name,
                        inputs: this.scenario
                    },
                    method: 'POST'
                }).then((response: any) => {
                    this.loading_create_task = false;
                    this.clearScenario();
                    console.log('Task Submitted!')
                    bus.$emit('task_submitted', response.data);
                    this.onClose()
                }, (error: any) => {
                    this.loading_create_task = false;
                    if (!isNullOrUndefined(error.response.data.detail)) {
                        this.error_msg = error.response.data.detail;
                    } else {
                        this.error_msg = "An error occurred submitting this task."
                    }
                    console.log(error);
                })

            }
        })
    }


    // Check if the task name has any invalid characters on input
    validateTaskName(task_name: any) {
        this.rules = {
            task_name_rules: [
                function () {
                    if (/[/%\\#?]/.test(task_name)) {
                        return "Characters /, \\, ?, # and % are not allowed in task name"
                    } else {
                        return !/[/%\\#?]/.test(task_name)
                    }
                },
            ]
        }
    }


    // Fit the thumbnail map to the bounds of the chosen roads to close.
    fitBounds(bbox: any) {
        if (this.$data.map) {

            // Get coords from a bbox string
            const regex = /-?\d+\.\d+/g;
            var [minLng, minLat, maxLng, maxLat] = bbox.match(regex)?.map(parseFloat) || [];

            // Create a LngLatBounds object
            var bounds = new mapboxgl.LngLatBounds(
                [minLng, minLat],
                [maxLng, maxLat]
            );

            // Fit bounds
            this.$data.map.fitBounds(bounds, {
                padding: 50, // Padding around the bounds
            });
        }

    };


    // Get the bounds of the segments to close off.
    getBounds() {
        return new Promise((resolve, reject) => {
            Vue.axios({
                url: endpoints.taskClosedSegmentsBoundsUrl(this.dataset),
                data: {
                    inputs: this.scenario
                },
                method: 'POST'
            }).then(response => {
                console.log('Bounding box requested!')
                resolve(response);
            }, (error: any) => {
                reject(error);
            });
        })
    };

    // Zoom to extent
    zoomToExtent() {
        var vm = this
        this.getBounds().then((response: any) => {
            if (response.data[0]) {
                vm.fitBounds(response.data[0])
            }
        })
    };


    // Close the scenario editor dialog.
    onClose() {
        bus.$emit('scenario_editor', false);
        this.error_msg = "";
    };


    // Map is updating.
    onMapData(e: any) {
        if (e.mapboxEvent.tile) {
            this.loading_map = !this.$data.map.areTilesLoaded()
        }
    };


    // Map becomes idle.
    onMapIdle(e: any) {
        this.loading_map = !this.$data.map.areTilesLoaded()
    };


    task_list() {
        bus.$emit('task_list_dialog', true);
    };

    confirmationDialog() {
        this.confirmDialog = true;
    };

    cancelTaskSubmit() {
        this.confirmDialog = false;
    };

    confirmTaskSubmit() {
        this.confirmDialog = false;
        this.onSubmitTask();
    }

    learnMore() {
        (window as any).open('./docs/user_guide_scenarios.pdf');
    }
}




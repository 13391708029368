

























import NavBar from '@/components/benchmark/NavBarBM.vue';
import BenchmarkPanel from '@/components/benchmark/BenchmarkPanel.vue';

export default {
    components: {
        NavBar,
        BenchmarkPanel
    },
    data: function () {
        return {
            count: 0,
            modeFilter: undefined,
            commodityFilter: undefined,
            supplyChainFilter: undefined,
            temporalTrips: undefined,
            spatialFilterFrom: undefined,
            spatialFilterTo: undefined,
        }
    },

    methods: {
        modeFilterOnChange: function (value: string) {
            var val = value.length === 2 ? "" : value // if road and rail are selected (i.e all), then remove filter
            this.modeFilter = val
        },
        commodityFilterOnChange: function (value: string) {
            this.commodityFilter = value
        },
        supplyChainFilterOnChange: function (value: string) {
            this.supplyChainFilter = value
        },
        spatialFilterFromOnChange: function (value: string) {
            this.spatialFilterFrom = value
        },
        spatialFilterToOnChange: function (value: string) {
            this.spatialFilterTo = value
        },
        supplychain_filter_close: function () {
            this.$refs['bm'].clearSupplyChainFilter()
        },
        spatialFilterOnCloseChip: function (value: string) {
            console.log(value)
            if (value.startsWith('From')) {
                this.$refs['bm'].clearMapDeparturesOnClick()
            }
            if (value.startsWith('To')) {
                this.$refs['bm'].clearMapArrivalsOnClick()
            }
        },
        supplyChainFilterOnCloseChip: function (value: string) {
            if (value.startsWith('From:')) {
                this.$refs['bm'].clearOrigEnterpriseFilter()
            }
            if (value.startsWith('To:')) {
                this.$refs['bm'].clearDestEnterpriseFilter()
            }
        },
        temporalTripsOnChange: function (value: boolean) {
            this.temporalTrips = value
            //this.$refs['bm'].loadCommodityTables();


        },
    }
}










































import { Component, Prop, Vue, Watch, Inject } from 'vue-property-decorator';

import LegendItem from './LegendItem.vue';

Vue.config.productionTip = false

@Component({
    components: {
        LegendItem
    }
})
export default class EnterpriseLegend extends Vue {


    legendTitle(layer: string): string {
        return (this.layerStyles as any)[layer].title
    }

    // Legend bindings
    private layerStyles = {
        trailer_loads: {
            title: "Enterprises",
            items: [
                { color: "#99ceff", value: "Processor" },
                { color: "#cc3300", value: "Manufacturer" },
                { color: "#FF69B4", value: "Mill" },
                { color: "#ff9999", value: "Storage" },
                { color: "yellow", value: "Transport" },
                { color: "#33FF8D", value: "Distribution" },
                { color: "red", value: "Retail" },
                { color: "#ffb31a", value: "Mining" },
                { color: "#8976cf", value: "Waste" },
                { color: '#C2B280', value: "Construction" },
                { color: '#fcc0a5', value: "Health" },
                { color: "#979A9A", value: "Production" },
            ],

        },
    }


}



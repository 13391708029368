import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { MapState } from './types';
import { RootState } from '../types';

export const state: MapState = {
    layerStyle: 'trailer_loads',
    featureOfInterest: null,
    hiddenLayers: [],
    flyTo: [],
};

const namespaced: boolean = true;

export const map: Module<MapState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};




























































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { AssistantState } from '@/store/assistant/types';
import Conversation from '@/components/assistant/Conversation.vue';

import { bus } from '@/pages/transitweb/main'
import { isNullOrUndefined } from 'util';
import WebSpeechRecognize from '@/components/assistant/WebSpeechRecognize.vue'

enum PermissionStatus {
    granted = "granted",
    prompt = "prompt",
    denied = "denied",
}

@Component({
    components: {
        Conversation,
        'vue-web-speech': WebSpeechRecognize
    }
})
export default class Assistant extends Vue {

    @State('assistant') assistant!: AssistantState;
    @Action('assistant/setStandAlone') setStandAlone: any;
    @Action('assistant/clearMessages') clearMessages: any;
    @Action('assistant/setSubject') setSubject: any;
    @Action('assistant/setQuestion') setQuestion: any;
    @Action('assistant/askQuestion') askQuestion: any;
    @Action('assistant/addAssistantMessage') addMessage: any;
    @Action('assistant/setSyncWithMap') setSyncWithMap: any;
    @Action('assistant/setPlayAudioResponses') setPlayAudioResponses: any;

    @Getter('assistant/assistantName') assistantName!: string;

    subjects: any = [
        { id: 'general', label: 'Supply Chains' },
        //{ id: 'popular_routes', label: 'Popular Routes' },
        //{ id: 'orig_dest', label: 'Origins/Destinations' },
        //{ id: 'metrics', label: 'Metrics' },
        //{ id: 'imports', label: 'Imports' },
        //{ id: 'exports', label: 'Exports' },
        //{ id: 'seasonal', label: 'Seasonal Variations' },
        //{ id: 'resilience', label: 'Resilience' },
        { id: 'transit_web', label: 'TraNSIT Web Guidance', disabled: false },
    ]

    recording_timeout: any;
    recording: boolean = false;
    mic_loading: boolean = false;
    mic_stream: any = null;
    mic_status: PermissionStatus = PermissionStatus.denied;
    attachments: File[] = [];


    @Watch('recording')
    onRecordingChanged(val: any, oldVal: any) {
        if (val == true) {
            //console.log("Recording started...")
            this.setQuestion("");
        } else {
            //console.log("Recording stopped!")
            (this.$refs['mic_off'] as any).play();
          }
    }


    @Watch('mic_status')
    onMicChanged(val: any, oldVal: any) {
        if (oldVal == PermissionStatus.granted && val == PermissionStatus.prompt) {
            (this.$refs['mic_disconnect'] as any).play()
         }
    }

    onAudioResult(results: any) {

        console.log('Results received', results);

        // Set the question to be asked.
        var question = this.capitalizeFirstLetter(results.join(""));
        if (!isNullOrUndefined(question)) {
            this.setQuestion(question);
        }

        // Reset the timeout if a new message comes through.
        if (this.recording_timeout) {
            console.log("Clearing timeout:", this.recording_timeout);
            clearTimeout(this.recording_timeout);
        }

        // Set a new timeout to stop recording within 2 seconds.
        this.recording_timeout = setTimeout(() => {
            console.log("Timeout was reached");
            this.stopRecording();
        }, 2000);
        console.log("New timeout:", this.recording_timeout);

        //console.log(this.assistant.question);
    }

    // Ask a question when audio has been turned off.
    onAudioEnd(results: any) {
        // Triggers on safari: Could not play audio NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.
        console.log('Audio End', results);
    }

    onSpeechEnd(e: any) {
        // Triggers on safari: Could not play audio NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.
        console.log('Speech End', e);
    }

    onSoundEnd(e: any) {
        console.log('Sound End', e);
    }

    onEnd(e: any) {
        console.log('End', e);
        this.ask();
    }

    onAudioError(error: any) {
        this.addMessage({ content: error.message.toString(), status: "error" });
    }

    // Ask the assistant the question
    async ask() {
        if (!this.question || this.question.length == 0) {
            return;
        }
        if (!this.assistant.question.endsWith("?")) {
            this.setQuestion(this.assistant.question + "?");
        }
        if (this.recording) {
            console.log("Time to ask question after recording");
            this.stopRecording();
        } else {
            var images: any[] = []
            for (var attachment of (this.attachments as File[])){
                var imageData = (await this.getBase64(attachment) as string);
                images.push({src: imageData, title: attachment.name});
                //console.log('imageData', imageData);
            }
            (this.attachments as File[]) = [];
            this.askQuestion({ content: this.capitalizeFirstLetter(this.assistant.question), subject: this.assistant.subject, images: images });
        }
    }

    getBase64(file: any) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }

    capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    updatePermissionStatus(e: any) {
        //console.log("New Microphone Permission: ", e);
        this.mic_status = e.target.state as PermissionStatus;
    }

    getMicrophonePermissions(init: boolean = false) {
        var permissionPromise = navigator.permissions.query({ name: "microphone" })
            .then((result) => {

                //console.log("Microphone Permission: ", result);
                this.mic_status = PermissionStatus[result.state];

                if (init) {
                    //result.addEventListener("change", this.updatePermissionStatus);
                    result.onchange = (this.updatePermissionStatus)
                }
            });
        return permissionPromise;
    }

    askMicrophonePaermission(displayGrantMessage = true) {
        var vm = this;
        navigator.mediaDevices
            .getUserMedia({ video: false, audio: true })
            .then((stream) => {
                if (displayGrantMessage) { this.addMessage({ content: "Permission to use the microphone has been granted. You may start speaking...", status: "success" }); }
                this.mic_stream = stream;
                this.startSpeaking();
            })
            .catch((error) => {
                //console.log(error);
                if (error.toString().startsWith("NotAllowedError")) {
                    this.addMessage({ content: "You have decided to decline permission to use the microphone, however you can still ask me questions via your keyboard.", status: "error" });
                } else {
                    this.addMessage({ content: error.toString(), status: "error" });
                }
            }).finally(function () { vm.getMicrophonePermissions(); });
    }

    speechToText() {
        if (!this.recording) {
            this.getMicrophonePermissions().then(() => {
                if (this.mic_status == PermissionStatus.denied) {
                    this.addMessage({ content: "Microphone access is currently denied, you need to provide access to the microphone through your browsers settings.", status: "error" });
                    return;
                } else if (this.mic_status == PermissionStatus.prompt) {
                    this.addMessage({ content: "If you would like to access the microphone for voice, please accept the permission request.", status: "info" });
                    this.askMicrophonePaermission();
                } else {
                    this.askMicrophonePaermission(false);
                }
            })
        } else {
            console.log("Recording has changed state to stopped")
            this.stopRecording();
        }
        
    }

    startSpeaking() {
        (this.$refs['mic_on'] as any).play().then(() => {
            this.mic_loading = true;
            console.log("Playing sound...") // Play the fancy sound the start transcribing speech after audoi sound ended.
        }).catch(() => {
            console.log("Unable to play sound") // If the fancy noise couldn't be played just start transcribing speech.
            this.recording = true;
        })
    }

    stopRecording() {
        console.log("Recording was stopped")
        this.recording = false;
        this.mic_stream.getTracks().forEach((track: any) => {
            track.stop();
        })
    }

    get microphoneIcon() {
        return this.mic_status == PermissionStatus.granted || this.mic_status == PermissionStatus.prompt ? 'mdi-microphone' : 'mdi-microphone-off';
    }

    get microphoneColor() {
        if (this.recording || this.mic_loading) {
            return 'red';
        } else if (this.mic_status == PermissionStatus.granted) {
            return 'primary';
        } else {
            return null;
        }
    }

    get subject(): string {
        return this.assistant.subject
    }
    set subject(subject: string) {
        this.setSubject(subject)
    }

    get question(): string {
        return this.assistant.question
    }
    set question(question: string) {
        this.setQuestion(question)
    }

    assistant_dialog: boolean = false;
    @Watch('assistant_dialog')
    onDialogChanged(val: boolean, oldVal: boolean) {
        bus.$emit('assistant_dialog', val);
    }

    @Prop() private isStandAlone!: boolean;
    @Prop() private assistant_open!: boolean;

    @Watch('assistant_open')
    onDialogPropChanged(val: boolean, oldVal: boolean) {
        this.assistant_dialog = val
    }

    @Watch('assistant.isStandAlone')
    onIsStandAloneChanged(val: boolean, oldVal: boolean) {
        if (oldVal == true) {
            // If going from stand alone back to TraNSIT, open the dialog, but only on TraNSIT (there's a condition on App.vue)
            bus.$emit('assistant_dialog', true);
        }
    }

    transfer_back() {
        this.setStandAlone(false);
        this.setSyncWithMap(false);
        window.close()
    }

    transfer_new_window() {
        this.setStandAlone(true);
        bus.$emit('assistant_dialog', false);
        const routeData = this.$router.resolve({ name: 'Assistant' });
        window.open(routeData.href, '_blank')
    }

    onClose() {
        this.setSyncWithMap(false);
        if (this.isStandAlone) {
            //this.setStandAlone(false);
            window.close();
        } else {
            bus.$emit('assistant_dialog', false);
        }
    }

    created() {
        bus.$on('focus_question', () => {
            (this.$refs['question'] as any).focus();
        });
    }

    mounted() {
        if (this.isStandAlone) {
            this.assistant_dialog = true;
        }
        this.getMicrophonePermissions(true);
        setInterval(() => {
            this.getMicrophonePermissions();
        }, 10000);
    }

    updated() {
        this.$nextTick(() => { // Needs to be delayed to allow the DOM to update properly when isStandAlone
            var conversation = (this.$refs.conversation as any)
            if (!isNullOrUndefined(conversation) && !isNullOrUndefined(conversation.$el)) {
                conversation.$el.scrollTop = conversation.$el.scrollHeight - conversation.$el.clientHeight;
            }
        })
    }

    get syncWithMap(): boolean {
        return this.assistant.syncWithMap
    }
    set syncWithMap(value: boolean) {
        this.setSyncWithMap(value);
    }

}




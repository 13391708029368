





























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { bus } from '@/pages/transitweb/main'
import { MapState } from '@/store/map/types';

import InfoPanelDashboard from './InfoPanelDashboard.vue';
import CO2 from './CO2.vue';

@Component({
    components: {
        CO2,
        InfoPanelDashboard
    }
})
export default class InfoPanelEnterprise extends Vue {

    @Prop() private info!: any;

    @Prop() private unitType!: string;
    @Prop() private dataColumn!: string;
    @Prop() private statisticType!: string;
    @Prop() private isEnterpriseInfo!: boolean;

    @State('map') mapstate!: MapState;
    @Getter('auth/isAllowedSensitiveInfo') isAllowedSensitiveInfo!: boolean;

    @Action('map/setFlyTo') setFlyTo: any;

    get enterprise(): any {
        return this.info || this.mapstate.featureOfInterest.properties;
    }

    // TODO: This needs some comments as it is currently meaningless!
    get isSensitive(): boolean {
        if (this.enterprise.enterprise.startsWith("silo") || (this.enterprise.sensitive === 0)) {
            return true;
        }
        return false;
    }

    onClose() {
        this.$emit('closed', this)
    }

    onOutbound() {
        var e: any = [this.enterprise.ent_id]
        //this.$emit('outbound', 'outbound', e, true)
        bus.$emit('add_special_filter', 'outbound', e, true)
    }

    onInbound() {
        var e: any = [this.enterprise.ent_id]
        //this.$emit('inbound', 'inbound', e, true)
        bus.$emit('add_special_filter', 'inbound', e, true)
    }


}




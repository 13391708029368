























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { MessageState } from '@/store/assistant/types';


@Component({
    components: {
    }
})
export default class RelatedQuestions extends Vue {

    @Action('assistant/askQuestion') askQuestion: any;
    @Getter('assistant/assistantVerb') assistantVerb!: string;
    @Getter('assistant/relatedQuestions') relatedQuestions!: MessageState[];

}

